import { createReducer, on } from '@ngrx/store';
import { addToCart, removeFromCart, clearCart, checkout, checkoutSuccess, checkoutFailure, setSinglePurchaseRestriction, incrementQuantity, decrementQuantity, selectColor, initializeCart } from '../actions/cart.actions';
import { CartItem } from '../models/cart-item.model';

export interface CartState {
  items: CartItem[];
  loading: boolean;
  error: any;
  singlePurchaseRestrictions: string[];
}

export const initialState: CartState = {
  items: [],
  loading: false,
  error: null,
  singlePurchaseRestrictions: []
};

export const cartReducer = createReducer(
  initialState,
  on(addToCart, (state, { product }) => {
    const existingItem = state.items.find(item => item.product.productDetails.productUid === product.productDetails.productUid);
    if (existingItem) {
      return {
        ...state,
        items: state.items.map(item =>
          item.product.productDetails.productUid === product.productDetails.productUid
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      };
    }
    return {
      ...state,
      items: [...state.items, { product, quantity: 1 }]
    };
  }),
  on(setSinglePurchaseRestriction, (state, { productId }) => ({
    ...state,
    singlePurchaseRestrictions: [...state.singlePurchaseRestrictions, productId]
  })),
  on(removeFromCart, (state, { productId }) => ({
    ...state,
    items: state.items.filter(item => item.product.productDetails.productUid !== productId),
    singlePurchaseRestrictions: state.singlePurchaseRestrictions.filter(id => id !== productId)
  })),
  on(initializeCart, (state, { items }) => {
    console.log('Initializing cart with items:', items);
    return {
      ...state,
      items,
      singlePurchaseRestrictions: items.filter((item: CartItem) => item.product.singlePurchaseOnly).map((item: CartItem) => item.product.productDetails.productUid)
    };
  }),
  on(clearCart, state => ({
    ...state,
    items: [],
    singlePurchaseRestrictions: []
  })),
  on(checkout, state => ({
    ...state,
    loading: true
  })),
  on(checkoutSuccess, state => ({
    ...state,
    loading: false,
    items: [],
    singlePurchaseRestrictions: [] // Clear restrictions on checkout success
  })),
  on(checkoutFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(incrementQuantity, (state, { productId }) => ({
    ...state,
    items: state.items.map(item =>
      item.product.productDetails.productUid === productId ? { ...item, quantity: item.quantity + 1 } : item
    )
  })),
  on(decrementQuantity, (state, { productId }) => ({
    ...state,
    items: state.items.map(item =>
      item.product.productDetails.productUid === productId && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
    )
  })),
  on(selectColor, (state, { productId, color }) => ({
    ...state,
    items: state.items.map(item =>
      item.product.productDetails.productUid === productId ? { ...item, selectedColor: color } : item
    )
  }))
);
