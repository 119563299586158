import { Product } from '../../../store/models/product.model';

const NFT_BABY_LUNA_EPIC: Product = {
  productDetails: {
    productUid: 'bFgXcH7VpLm8QkNzRt5y',
    productNumber: 'NFT-004',
    productName: 'NFT Baby Luna Epic',
    productDescription: 'Exclusive Baby Luna NFT with superior attributes, a must-have for discerning collectors.',
    productFeatures: `
    Masterfully crafted digital art that embodies the epic rarity level within the Baby Luna collection. This NFT features superior attributes, including rare traits and a high level of artistic detail that sets it apart from lower rarities. Ownership is securely verified on the blockchain, ensuring the NFT’s authenticity and unique identity within the Ethereum network. With its very limited supply, the Epic Baby Luna NFT is highly sought after in secondary markets, where its exclusivity and artistic merit make it a valuable and prestigious collectible. This NFT is not just a digital asset but a symbol of status and refined taste in the world of NFTs.
  `,
      productInformation: `
    The Epic Baby Luna NFT is a masterpiece within the collection, designed to appeal to serious collectors and investors who value exclusivity and exceptional craftsmanship. This NFT is not just a digital collectible but a work of art that showcases superior attributes, including intricate details, rare traits, and a distinct aesthetic that sets it apart from the lower rarity levels. With its very limited availability, the Epic Baby Luna is a highly sought-after item, representing both a status symbol and a significant investment in the NFT space. Owners of this NFT are not just collectors; they are curators of a unique digital asset that is expected to increase in value as the collection grows in popularity and recognition.
  `,
      productStart: new Date(),
      productPublished: false,
  },
  productSpecifics: {
    productType: 'nft',
    fileName: 'nft-baby-luna-epic',
    productCollection:'Luna Baby',
    firestoreCollection: 'NFT_LunaBaby',
    affiliateUrl: '',
    smartContractDetails: `
  The Epic Baby Luna NFT is governed by a premium ERC-721 smart contract, tailored for managing highly limited digital assets with superior attributes. With a mint fee of 0.7 ETH, the contract provides top-tier security and ownership verification on the Ethereum blockchain. The Epic rarity level is characterized by its significant scarcity and enhanced features, making it a prized possession for serious collectors. The contract includes specialized functionalities that maintain the exclusivity of these NFTs, while allowing safe and efficient trading on various platforms.
`,

  },
  cost: {
    priceAmountSingleItem: 0.7,
    priceCurrency: 'ETH',
    tax: 'none',
    tieredPricing: [],

  },
  images: [
    { imageURL: 'babyunicorn.webp', imageName: 'Epic Baby Luna NFT Image' },
  ],
  colors: [],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered instantly on purchase' },
    { icon: 'fas fa-truck', info: 'Blockchain verified' },
    { icon: 'fas fa-undo-alt', info: 'No refunds' },
  ],
};

export default NFT_BABY_LUNA_EPIC;
