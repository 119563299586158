import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private router: Router) {}

  public getTranslation(lang: string): Observable<any> {
    const page = this.getPageFromUrl(this.router.url);
    const path = `/assets/i18n/pages/${page}/${lang}.json`;
    return this.http.get<any>(path);
  }

  private getPageFromUrl(url: string): string {
    const path = url.split('?')[0];
    const segments = path.split('/').filter(segment => segment);
    return segments.length > 0 ? segments[0] : 'home';
  }
}
