import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc, getDocs, updateDoc, collection, query,where,  serverTimestamp } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { FieldsUserFirestore } from '../../store/models/user.model';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class UsersRudService {
  private firestore: Firestore;

  constructor() {
    this.firestore = getFirestore();
  }

  getUser(uid: string): Observable<FieldsUserFirestore | undefined> {
    const userDocRef = doc(this.firestore, 'Users', uid);
    return from(getDoc(userDocRef)).pipe(
      map(snapshot => {
        if (snapshot.exists()) {
          return snapshot.data() as FieldsUserFirestore;
        } else {
          return undefined;
        }
      }),
      catchError(error => {
        throw error;
      })
    );
  }
  getUserByEmail(email: string): Observable<FieldsUserFirestore | undefined> {
    const q = query(collection(this.firestore, 'Users'), where('authentication.userEmail', '==', email));
    return from(getDocs(q)).pipe(
      map(snapshot => {
        if (!snapshot.empty) {
          return snapshot.docs[0].data() as FieldsUserFirestore;
        } else {
          return undefined;
        }
      }),
      catchError(error => throwError(() => new Error('Failed to get user by email: ' + error)))
    );
  }

  async updateUser(uid: string, data: Partial<FieldsUserFirestore>): Promise<void> {
    try {
      const userDocRef = doc(this.firestore, 'Users', uid);
      const existingUserSnap = await getDoc(userDocRef);
      let updateData: Partial<FieldsUserFirestore> = data;
      if (existingUserSnap.exists()) {
        const existingData = existingUserSnap.data() as FieldsUserFirestore;
        updateData = { ...existingData, ...data };
      } else {
        updateData = data;
      }
      console.log('Updating user data:', updateData);
      await updateDoc(userDocRef, updateData);
      console.log('User data updated successfully');
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  }
  

  async deleteUser(uid: string): Promise<void> {
    try {
      const userDocRef = doc(this.firestore, 'Users', uid);
      await updateDoc(userDocRef, { 'authentication.markedForDeletion': true, 'authentication.markedForDeletionDate': serverTimestamp() });
    } catch (error) {
      console.error('Error deactivating user:', error);
      throw error;
    }
  }

  async getAllUsers(): Promise<FieldsUserFirestore[]> {
    const usersSnapshot = await getDocs(collection(this.firestore, 'Users'));
    return usersSnapshot.docs.map(doc => doc.data() as FieldsUserFirestore);
  }




}
