<div id="newsletter-wrapper">
  <div class="flex-layout">
    <div class="left-column">
      <div class="centered-content">
        <div class="box-text">
          <div class="text-main-header">{{ 'newsletter.header' | translate }}<span>{{
              'newsletter.header-special' | translate }}</span></div>
          <div class="normal-text">{{ 'newsletter.description' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="right-column">
      <div class="centered-content email-section">
        <div class="message-box" *ngIf="subscriptionStatus === 'success'" class="success-message">
          {{ 'newsletter.successMessage' | translate }}
        </div>
        <div class="message-box" *ngIf="subscriptionStatus === 'failure'">
          <div class="failure-message">
            {{ 'newsletter.failureMessage' | translate }}
          </div>
          <button class="button-blue" (click)="resetSubscriptionStatus()">
            <span>{{ 'newsletter.retryButton' | translate }}</span>
          </button>
        </div>
        <div class="message-box" *ngIf="subscriptionStatus === 'blocked'">
          <div class="blocked-message">
            {{ 'newsletter.blockedMessage' | translate }}
          </div>
          <button class="button-blue" (click)="resetSubscriptionStatus()">
            <span>{{ 'newsletter.retryButton' | translate }}</span>
          </button>
        </div>
        <div *ngIf="subscriptionStatus === 'none'">
          <mat-form-field appearance="fill" class="email-input">
            <mat-label>{{ 'newsletter.emailLabel' | translate }}</mat-label>
            <input matInput placeholder="{{ 'newsletter.emailPlaceholder' | translate }}"
              [formControl]="emailControl">
            <mat-error *ngIf="emailControl.hasError('required')">
              {{ 'newsletter.emailRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="emailControl.hasError('email')">
              {{ 'newsletter.emailInvalid' | translate }}
            </mat-error>
          </mat-form-field>
          <div class="button-content">
            <button class="button-blue" (click)="subscribeToNewsletter()" [disabled]="emailControl.invalid">
              <span>{{ 'newsletter.subscribeButton' | translate }}</span>
            </button>
          </div>
          <div class="disclaimer-text">{{ 'newsletter.disclaimer' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="footer">
  <div class="footer-wrapper">
    <footer class="footer-container">
      <div class="tile bottom-tile">
        <a routerLink="/terms">terms</a>
        <a routerLink="/cookie">cookie</a>
        <a routerLink="/privacy">privacy</a>
      </div>
      <div class="tile bottom-cop">
        <span>© 2024 magic-horn in corporation with YingYang llc. | All rights reserved. | Version: {{ appVersion }}</span>
      </div>
    </footer>
  </div>
</div>
