import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import lottie, { AnimationItem } from 'lottie-web';
import { Observable } from 'rxjs';
import { NewsletterSubscriptionService } from '../../../services/user/newsletter-subscription.service';
import { SnackbarService } from '../../../components/snack-bar/snack-bar.service';
import { LanguageService } from '../../../services/admin/language.service';
import { AuthService } from '../../../services/user/user-auth.service';
import { LanguagesMapping } from '../../../store/models/language.model';
import { CommonModule } from '@angular/common';
import { BugSmashComponent } from '../../../components/bug-smash/bug-smash.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { environment } from '../../../../environments/environment';

type SubscriptionStatus = 'success' | 'failure' | 'none' | 'blocked';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    BugSmashComponent,
    MatSlideToggleModule
  ]
})
export class FooterComponent implements OnInit, OnDestroy {
  languages$: Observable<LanguagesMapping>;
  currentLanguage: string = 'en';
  appVersion = environment.appVersion; 
  @ViewChild('lottieContainer') lottieContainer?: ElementRef;
  private animationItem: AnimationItem | null = null;
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  email: string = '';
  subscriptionStatus: SubscriptionStatus = 'none'; 
  locationControl = new FormControl('');
  subscribeToUserLead = new FormControl(false);

  isLoggedIn$: Observable<boolean> = this.authService.isAuthenticated();

  constructor(
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private languageService: LanguageService,
    public router: Router,
    private newsletterService: NewsletterSubscriptionService,
  ) {
    this.languages$ = this.languageService.allLanguages$;
  }

  ngOnInit() {
    this.languageService.getCurrentLanguage().subscribe(lang => {
      this.currentLanguage = lang;
    });
    this.locationControl.disable();
  }

  ngAfterViewInit(): void {
    this.initLottie();
  }

  private initLottie(): void {
    if (this.lottieContainer) {
      this.animationItem = lottie.loadAnimation({
        container: this.lottieContainer.nativeElement,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'assets/lottie/discord.json'
      });
    }
  }

  openDiscordLink(): void {
    window.open('https://discord.gg/EEsJyxpeH3', '_blank');
  }

  openInstagramLink(): void {
    window.open('https://www.instagram.com/_mikes_world/', '_blank');
  }

  openYouTubeLink(): void {
    window.open('https://www.youtube.com/channel/UCFDiB2L2vnjhiECZ2KwMe0Q', '_blank');
  }

  openSpotifyLink(): void {
    window.open('https://open.spotify.com/playlist/7oMCviAKL6HEicLHkU5OHy?si=6866ca17f92248ee&pt=6f0daba70f591edc9156768d49a6fc9c', '_blank');
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.animationItem?.destroy();
  }

  async subscribeToNewsletter() {
    try {
      const subscriptionData = {
        email: this.emailControl.value ?? '',
        newsletterTypes: {
          genericNewsletter: true,
          userLead: this.subscribeToUserLead.value || false,
          userExisting: false
        }
      };
      await this.newsletterService.subscribeToNewsletter(subscriptionData);
      this.subscriptionStatus = 'success';
    } catch (error) {
      const errorMessage = (error as Error).message;
      if (errorMessage === 'Email Blocked') {
        console.error('Email is blocked:', error);
        this.subscriptionStatus = 'blocked';
      } else {
        console.error('Error subscribing to the newsletter:', error);
        this.subscriptionStatus = 'failure';
      }
    }
  }

  resetSubscriptionStatus() {
    this.subscriptionStatus = 'none';
  }

  showFailureMessage() {
    this.subscriptionStatus = 'failure';
    setTimeout(() => {
      this.subscriptionStatus = 'none';
    }, 5000);
  }
}
