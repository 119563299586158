import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { from, of, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import {
  getAuth,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  sendEmailVerification,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  Auth,
  UserCredential,
  User,
  browserLocalPersistence
} from 'firebase/auth';
import { doc, getFirestore, getDoc, setDoc, DocumentSnapshot, Firestore } from 'firebase/firestore';
import { FieldsUserFirestore } from '../../store/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private auth: Auth;
  private firestore: Firestore;
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  private authStateSubscription: Subscription | null = null;

  public get currentUser(): User | null {
    return this.currentUserSubject.value;
  }

  authEvent = new EventEmitter<{ type: string, success: boolean, userEmail?: string, message?: string }>();

  constructor() {
    this.firestore = getFirestore();
    this.auth = getAuth();
    this.initializeAuthPersistence();
    this.initAuthStateListener();
  }

  private initializeAuthPersistence() {
    this.auth.setPersistence(browserLocalPersistence).then(() => {
      document.cookie = "session=authenticated; SameSite=None; Secure";
    }).catch(error => {
      console.error("Error setting persistence:", error);
    });
  }

  private initAuthStateListener() {
    this.authStateSubscription = new Observable<User | null>(observer => {
      const unsubscribe = onAuthStateChanged(this.auth, user => {
        observer.next(user as User | null);
      });
      return () => unsubscribe();
    }).subscribe(user => {
      this.currentUserSubject.next(user);
    });
  }

  ngOnDestroy(): void {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  authState(): Observable<User | null> {
    return new Observable<User | null>(observer => {
      const unsubscribe = onAuthStateChanged(this.auth, user => {
        observer.next(user as User | null);
      });
      return () => unsubscribe();
    });
  }

  getCurrentUserId(): string | null {
    return this.auth.currentUser?.uid || null;
  }

  loginUser(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((credential) => {
        this.authEvent.emit({
          type: 'login',
          success: true,
          userEmail: credential.user.email ?? undefined
        });
        return credential;
      })
      .catch((error) => {
        this.authEvent.emit({
          type: 'login',
          success: false,
          message: 'Login failed: ' + error.message
        });
        throw error;
      });
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return from(firebaseSendPasswordResetEmail(this.auth, email));
  }

  logoutUser(): Promise<void> {
    const userEmail = this.currentUserSubject.value?.email ?? undefined;
    return signOut(this.auth)
      .then(() => {
        this.authEvent.emit({ type: 'logout', success: true, userEmail: userEmail });
      })
      .catch((error) => {
        this.authEvent.emit({ type: 'logout', success: false, message: 'Logout failed: ' + error.message, userEmail: userEmail });
        throw error;
      });
  }

  isAuthenticated(): Observable<boolean> {
    return this.authState().pipe(
      map(user => !!user)
    );
  }

  public get currentUser$(): Observable<User | null> {
    return this.currentUserSubject.asObservable();
  }

  getUserRoles(uid: string): Observable<FieldsUserFirestore['userRoles']> {
    return from(getDoc(doc(this.firestore, `Users/${uid}`))).pipe(
      map((userSnap: DocumentSnapshot) => {
        if (userSnap.exists()) {
          const userData = userSnap.data() as FieldsUserFirestore;
          if (userData.userRoles) {
            return userData.userRoles;
          } else {
            throw new Error('Roles not defined for user');
          }
        } else {
          throw new Error('Role cant be fetched, cant find user in Firestore');
        }
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  isUserInRole(role: string): Observable<boolean> {
    return this.currentUser$.pipe(
      switchMap((user: User | null) => {
        if (!user) return of(false);
        return this.getUserRoles(user.uid);
      }),
      map(userRoles => {
        if (typeof userRoles === 'object' && userRoles !== null) {
          return !!userRoles[role];
        }
        return false;
      }),
      catchError(() => of(false))
    );
  }

  isEmailVerified(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          subscriber.next(user.emailVerified);
        } else {
          subscriber.next(false);
        }
      });
    });
  }

  sendVerificationEmail(): Promise<void> {
    const user = this.auth.currentUser;
    if (user) {
      return sendEmailVerification(user);
    } else {
      return Promise.reject('No user logged in');
    }
  }
}
