// store/effects/cart.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { checkout, checkoutSuccess, checkoutFailure } from '../actions/cart.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StripeService } from '../../services/shop/stripe.service';

@Injectable()
export class CartEffects {
  checkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkout),
      mergeMap(action => {
        return this.stripeService.createPaymentIntent(
          action.totalAmount, 
          action.email, 
          action.userDetails, 
          action.userAddress, 
          action.products,
          action.currency
        ).pipe(
          map(response => checkoutSuccess({ clientSecret: response.clientSecret })),
          catchError(error => {
            console.error('Error creating payment intent:', error);
            return of(checkoutFailure({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private stripeService: StripeService
  ) {}
}
