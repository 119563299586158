import { Injectable } from '@angular/core';
import { Alchemy, AssetTransfersCategory, NftTokenType } from 'alchemy-sdk';
import { ethers, parseEther } from 'ethers';
import { setDoc, doc, Firestore, getFirestore, collection, where, query, getDocs } from 'firebase/firestore';
import { Product } from '../../store/models/product.model';
import { environment } from '../../../environments/environment';
import { Nft, MintSignatureResponse } from '../../store/models/nft.model';
import { SnackbarService } from '../../components/snack-bar/snack-bar.service';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { getFunctions } from 'firebase/functions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AlchemyService {
  private alchemy!: Alchemy;
  private functions: Functions;
  private firestore: Firestore;

  constructor(
    private router: Router,
    private snackbarService: SnackbarService,
  ) {
    this.initializeAlchemy();
    this.functions = getFunctions(undefined, 'europe-west3');
    this.firestore = getFirestore();
  }

  private initializeAlchemy() {
    const settings = {
      apiKey: environment.alchemyApiKey,
      network: environment.alchemyNetwork,
    };
    this.alchemy = new Alchemy(settings);
    console.log('Alchemy initialized with settings:', settings);
  }

  public async mintNft(
    product: Product,
    tokenURIJson: string,
    docId: string | undefined, 
    userAddress: string,
    collectionName: string,
    updateOverlayTextCallback?: (text: string) => void,
  ): Promise<void> {
    try {
      console.log('Starting NFT minting process...');
      if (!product.productSpecifics.smartContractAdress || !product.productSpecifics.smartContractABI) {
        throw new Error('Smart contract address or ABI is undefined');
      }
    
      if (!product.cost?.priceAmountSingleItem) {
        throw new Error('Product price is undefined');
      }
    
      const provider = new ethers.BrowserProvider((window as any).ethereum);
      await provider.send("eth_requestAccounts", []);
      console.log('Ethereum accounts requested.');
    
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(product.productSpecifics.smartContractAdress, product.productSpecifics.smartContractABI, signer);
      const tokenURI = tokenURIJson;
      const signature = await this.generateMintSignature(userAddress, tokenURI);
      console.log('Signature generated:', signature);
    
      const mintingFee = parseEther(product.cost.priceAmountSingleItem.toString());
      const transaction = await contract['createNFT'](
        await signer.getAddress(),
        tokenURI,
        signature,
        { value: mintingFee }
      );

      if (updateOverlayTextCallback) {
        updateOverlayTextCallback('Minting the NFT, please wait...');
      }
  
    
      console.log('Transaction initiated:', transaction);
      await transaction.wait();
      console.log('NFT minted successfully:', transaction);
      this.snackbarService.showCustomSnackbar('NFT minted successfully.');
    
      try {
        console.log('Attempting to find the Firestore document by tokenURIJson:', tokenURIJson);
        const docId = await this.findDocumentByTokenURI(collectionName, tokenURIJson);
        if (docId) {
          console.log('Document found with docId:', docId);
          await this.updateMintedStatus(docId, userAddress, collectionName);
          console.log('Firestore updated successfully.');
        } else {
          console.error('Failed to find document for the given tokenURIJson.');
          this.snackbarService.showCustomSnackbar('Failed to update Firestore: document not found.');
        }
      } catch (firestoreError) {
        console.error('Error updating Firestore document:', firestoreError);
        this.snackbarService.showCustomSnackbar('Error updating Firestore document.');
        return;
      }
    
      console.log('Navigating to mint-completed page.');
      this.router.navigate(['/shop/mint-completed'], {
        state: {
          transactionHash: transaction.hash,
          from: transaction.from,
          to: transaction.to,
          value: transaction.value.toString(),
          tokenURIJson: tokenURIJson,
          docId: docId,

        }
      });
    } catch (error: any) {
      if (error.code === 'ACTION_REJECTED') {
        console.log('Transaction rejected by the user.');
        this.snackbarService.showCustomSnackbar('Minting canceled by the user.');
      } else if (error.reason === 'Maximum supply reached') {
        console.log('Minting failed: Maximum supply reached.');
        this.snackbarService.showCustomSnackbar('Minting failed: Maximum supply reached.');
      } else {
        let errorMessage = 'Error minting NFT.';
        if (error.message.includes('Smart contract address or ABI is undefined')) {
          errorMessage = 'Smart contract configuration is missing. Please provide a valid smart contract address and ABI.';
        } else if (error.message.includes('network does not support ENS')) {
          errorMessage = 'ENS resolution failed. Please ensure the contract address is valid.';
        } else if (error.message.includes('insufficient funds')) {
          errorMessage = 'Insufficient funds to complete the transaction. Please ensure your wallet has enough ETH.';
        } else if (error.message.includes('gas required exceeds allowance')) {
          errorMessage = 'Transaction gas limit exceeded. Please try again with a higher gas limit.';
        } else {
          errorMessage = `Unexpected error: ${error.message}`;
        }
    
        console.error('Error minting NFT:', error);
        this.snackbarService.showCustomSnackbar(errorMessage);
      }
    }
  }
  
  

  private async findDocumentByTokenURI(collectionName: string, tokenURIJson: string): Promise<string | null> {
    console.log('Starting document search with tokenURIJson:', tokenURIJson);
    
    const nftCollection = collection(this.firestore, collectionName);
    const q = query(nftCollection, where('tokenURIJson', '==', tokenURIJson));
    
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        console.log('Document found:', doc.id, 'with tokenURIJson:', doc.data()['tokenURIJson']);
        return doc.id;
    } else {
        console.error('No matching document found for tokenURIJson:', tokenURIJson);
        return null;
    }
}


  async generateMintSignature(to: string, tokenURI: string): Promise<string> {
    console.log('Generating mint signature...');
    const secretKey = environment.nftSignerSecretKey;
    const callable = httpsCallable(this.functions, 'generateMintSignature');
    const result = await callable({ to, tokenURI, secretKey }) as HttpsCallableResult<MintSignatureResponse>;
    console.log('Signature generation result:', result.data.signature);
    return result.data.signature;
  }
  
  private async updateMintedStatus(docId: string, userAddress: string, collectionName: string): Promise<void> {
    console.log(`Updating Firestore document with docId: ${docId} in collection: ${collectionName}`);
    try {
      const nftRef = doc(this.firestore, collectionName, docId);
      const updatedData: Partial<Nft> = {
        minted: true,
        mintedFirstBy: userAddress,
        mintedFirstAt: new Date(),
        currentOwnedBy: userAddress,
        currentOwnedSince: new Date(),
      };
  
      await setDoc(nftRef, updatedData, { merge: true });
      console.log(`Updated Firestore document with docId: ${docId} in collection ${collectionName}`);
      this.snackbarService.showCustomSnackbar('Document updated successfully.');
    } catch (error) {
      console.error(`Error updating Firestore document ${docId} in collection ${collectionName}:`, error);
      this.snackbarService.showCustomSnackbar('Error updating Document.');
    }
  }

  public async getSentTransactions(fromAddress: string): Promise<void> {
    try {
      console.log('Fetching sent transactions for address:', fromAddress);
      const sentTransactions = await this.alchemy.core.getAssetTransfers({
        fromBlock: '0x0',
        fromAddress,
        category: [
          AssetTransfersCategory.ERC721,
          AssetTransfersCategory.EXTERNAL,
          AssetTransfersCategory.ERC20,
        ],
      });
      console.log('Sent transactions:', sentTransactions);
      this.snackbarService.showCustomSnackbar('Sent transactions fetched successfully.');
    } catch (error) {
      console.error('Error fetching transactions:', error);
      this.snackbarService.showCustomSnackbar('Error fetching sent transactions.');
    }
  }

  public async getNftMetadata(product: Product, tokenId: string): Promise<any> {
    try {
      console.log('Fetching NFT metadata for tokenId:', tokenId);
      if (!product.productSpecifics.smartContractAdress) {
        throw new Error('Smart contract address is undefined');
      }

      const metadata = await this.alchemy.nft.getNftMetadata(product.productSpecifics.smartContractAdress, tokenId, {
        tokenType: NftTokenType.ERC721,
      });
      console.log('NFT metadata fetched:', metadata);
      this.snackbarService.showCustomSnackbar('NFT metadata fetched successfully.');
      return metadata;
    } catch (error) {
      console.error('Error fetching NFT metadata:', error);
      this.snackbarService.showCustomSnackbar('Error fetching NFT metadata.');
      throw error;
    }
  }
}
