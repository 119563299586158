<div class="purchase-done-wrapper">
    <h2>Registration Complete!</h2>
    <p>Please find your registration details below:</p>
    
    <div class="registration-details">
      <mat-form-field appearance="fill">
        <mat-label>Email Address</mat-label>
        <input matInput [value]="email" readonly>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Recovery UID</mat-label>
        <input matInput [value]="recoveryUID" readonly>
      </mat-form-field>
      <button mat-icon-button matSuffix (click)="copyToClipboard(recoveryUID)">
        <mat-icon>content_copy</mat-icon>
      </button>
      
      <div *ngIf="userDetails">
        <h3>User Details</h3>
        <pre>{{ userDetails | json }}</pre>
      </div>
  
      <div *ngIf="userAddress">
        <h3>User Address</h3>
        <pre>{{ userAddress | json }}</pre>
      </div>
  
      <div *ngIf="userAccess">
        <h3>User Access</h3>
        <pre>{{ userAccess | json }}</pre>
      </div>
    </div>
    
    <p>Please save these details securely for your records.</p>
  </div>