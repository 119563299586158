<div id="start" class="full-screen-content">
    <div class="seperator-line-blue"></div>
    <div class="seperator-text-wrapper">
        <!-- Verified State -->
        <div *ngIf="verificationState === 'verified'">
            <div class="text-intro-header">{{ 'verifiedEmailMsg' | translate }}</div>
            <div class="text-main-header">{{ 'awesomeMsg' | translate }}</div>
        </div>
    
        <!-- Already Verified State -->
        <div *ngIf="verificationState === 'already_verified'">
            <div class="text-intro-header">{{ 'alreadyVerifiedMsg' | translate }}</div>
            <div class="text-main-header">{{ 'alreadyVerifiedMainMsg' | translate }}</div>
        </div>
    
        <!-- Server/Error State -->
        <div *ngIf="verificationState === 'error'">
            <div class="text-intro-header">{{ 'errorMsg' | translate }}</div>
            <div class="text-main-header">{{ 'errorMainMsg' | translate }}</div>
        </div>
    </div>
</div>
