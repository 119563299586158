<div class="product-details-page">
  <div class="product-details-wrapper">
    <div class="arrow-back">
      <button mat-icon-button (click)="navigateBack()">
        <i class="fas fa-arrow-left"></i>
      </button>
    </div>
    <div class="product-details-container" *ngIf="product; else loading">
      <div class="left-container">
        <div class="product-images" *ngIf="product.images?.length">
          <div class="main-image">
            <img [src]="getImageUrl(product.images[selectedImageIndex].imageURL)"
                 alt="{{ product.images[selectedImageIndex].imageName }}">
            <div *ngIf="product.images[selectedImageIndex].imageDescription" class="image-description">
              {{ product.images[selectedImageIndex].imageDescription }}
            </div>
            <div *ngIf="product.imageText" class="image-text">
              {{ product.imageText }}
            </div>
          </div>
          <div class="thumbnails" *ngIf="product.images.length > 1">
            <img *ngFor="let image of product.images; let i = index" 
                 [src]="getImageUrl(image.imageURL)"
                 [alt]="image.imageName" 
                 [class.selected]="i === selectedImageIndex" 
                 (click)="selectImage(i)">
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="product-header">
          <h1>{{ product.productDetails.productName }}</h1>
        </div>
        <div class="price">
          <div [innerHTML]="getProductPrice(product, quantity)"></div>
          <div class="tax" *ngIf="product.cost.tax !== 'none'">
            <ng-container *ngIf="product.cost.tax === 'incl'">incl. Tax</ng-container>
            <ng-container *ngIf="product.cost.tax === 'excl'">excl. Tax</ng-container>
          </div>
          <div class="tiered-pricing-container" *ngIf="showTieredPricingMessage">
            <div class="tiered-pricing-message">Tiered pricing available</div>
            <button mat-icon-button matTooltip="Tiered Pricing Options" aria-label="Tiered Pricing Options"
              (click)="openTieredPricingTab(tabGroup)">
              <i class="fas fa-info-circle"></i>
            </button>
          </div>
        </div>
        <div *ngIf="product.productSpecifics.productType === 'nft'; else nonNftControls">
          <div class="web3-button">
            <app-web3-modal 
              [selectedProduct]="product" 
              [productPrice]="getProductPrice(product, quantity)">
            </app-web3-modal>
          </div>
        </div>

        <ng-template #nonNftControls>
          <div *ngIf="product.productSpecifics.productType !== 'online_course' && product.colors?.length"
            class="color-options">
            <div class="colors">
              <div *ngFor="let color of product.colors" class="color-swatch"
                [class.selected]="color === (selectedColor$ | async)" (click)="selectColor(color)"
                matTooltip="{{ color.name }}" matTooltipPosition="above">
                <div *ngIf="color.bottomColor; else singleColor" class="color-top"
                  [style.background-color]="color.topColor" style="height: 50%;"></div>
                <div *ngIf="color.bottomColor" class="color-bottom" [style.background-color]="color.bottomColor"
                  style="height: 50%;"></div>
                <ng-template #singleColor>
                  <div class="color-top" [style.background-color]="color.topColor"></div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="quantity-wrapper" *ngIf="product.productSpecifics.productType !== 'online_course'">
            <div class="quantity-container">
              <button mat-icon-button (click)="decrementQuantity()" [disabled]="quantity <= 1">
                <i class="fas fa-minus"></i>
              </button>
              <mat-form-field appearance="fill">
                <input matInput type="number" [(ngModel)]="quantity" min="1" readonly>
              </mat-form-field>
              <button mat-icon-button (click)="incrementQuantity()">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <button class="button-blue" (click)="addToCart(product)"
            [disabled]="(isProductInCart(product) | async) || (hasSinglePurchaseRestriction(product) | async)">
            {{ (isProductInCart(product) | async) ? 'Already in Cart' : 'Add to Cart' }} | {{
            getTotalProductPrice(product, quantity) }}
          </button>
        </ng-template>

        <div class="additional-info" *ngIf="product.shopDetails?.length">
          <div *ngFor="let detail of product.shopDetails">
            <i [class]="detail.icon"></i> {{ detail.info }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-description-container">
    <mat-tab-group #tabGroup>
      <mat-tab label="Details">
        <div class="product-details">
          <p>{{ product?.productDetails?.productInformation }}</p>
        </div>
      </mat-tab>

      <mat-tab label="Features">
        <div class="product-features">
          <p>{{ product?.productDetails?.productFeatures }}</p>
        </div>
      </mat-tab>

      <mat-tab label="Tiered Pricing" *ngIf="showTieredPricingMessage">
        <div class="tiered-pricing">
          <ul>
            <li *ngFor="let tier of product?.cost?.tieredPricing ?? []">
              Tier {{ tier?.tier }}: {{ tier?.price?.toFixed(2) ?? 'N/A' }} {{ product?.cost?.priceCurrency || 'EUR' }}
              per item
            </li>
          </ul>
        </div>
      </mat-tab>

      <!-- New Tab for Smart Contract Details -->
      <mat-tab *ngIf="product?.productSpecifics?.productType === 'nft'" label="Smart Contract Details">
        <div class="product-details smart-contract-details">
          <p>{{ product?.productSpecifics?.smartContractDetails }}</p>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-template #loading>
    <div>Loading...</div>
  </ng-template>
</div>