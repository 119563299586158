<div class="feedback-wrapper">
  <div class="feedback-container">
    <div class="feedback-header">
      Join the Magic Horn Community
    </div>
    <div class="owl-wrapper">
      <owl-carousel-o [options]="customOptions" class="owl-carousel-custom">
        <ng-container *ngFor="let review of reviews">
          <ng-template carouselSlide>
            <div class="feedback-customer">
              <div class="customer-review">
                <p>" {{ review.text }} "</p>
                <div class="review-small">{{ review.name }} - {{ review.date }}</div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
