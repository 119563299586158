<div class="mint-completed-wrapper">
  <div class="mint-completed-container">
    <h1>Mint Completed</h1>

    <p><strong>Transaction Hash:</strong> {{ transactionHash }}</p>
    <p><strong>From:</strong> {{ from }}</p>
    <p><strong>To:</strong> {{ to }}</p>
    <p><strong>Value:</strong> {{ value }} ETH</p>
    <p><strong>Document UID:</strong> {{ docId }}</p>

    <div class="url-container">
      <div class="token-uri">
        <div class="uri-header">
          <div><strong>Token URI Json</strong></div>
          <button mat-icon-button (click)="copyToClipboard(jsonUrl)">
            <i class="fas fa-copy"></i>
          </button>
        </div>
        <div class="the-uri">{{ tokenURIJson }}</div>
        <button class="button-blue" (click)="openJsonInTab()">
          View JSON
        </button>
      </div>
    </div>

    <div class="image-container">
      <div>
        <img *ngIf="imageUrl" [src]="imageUrl" alt="NFT Image">
      </div>

      <div class="token-uri">
        <div class="uri-header">
          <div><strong>Token URI Image</strong></div>
          <button mat-icon-button (click)="copyToClipboard(imageUrl)">
            <i class="fas fa-copy"></i>
          </button>
        </div>
        <button class="button-blue" (click)="openImageInTab()">
          View Image
        </button>
      </div>
    </div>

  </div>
</div>
<div class="shop-button">
  <button class="button-blue" (click)="navigateBackToShop()">
    Back to Shop
  </button>
</div>