<div class="home-wrapper" #homeWrapper>
  <div class="start-container">
    <div class="banner-text">
      <div class="banner-text">
        <h1>The Legend of the Moonborn Unicorns</h1>
        <p>Discover the story which inspired our successful NFTs and coins.</p>
      </div>
    </div>
  </div>

  <div class="panel" data-color="white">
  </div>

  <div class="panel" data-color="red">
    <section class="the-story-wrapper">
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieWritingContainer></div>
      </div>
      <div class="the-story">
        <div class="story-header">
          <h2>Elysium</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>In a world where time itself seems to be woven with threads of magic, there existed a realm of
              unparalleled
              beauty and wonder. This was the land of Elysium, a place where mythical creatures roamed freely, and the
              air
              was filled with enchantment. Among all the magnificent beings that graced this world, none were as revered
              or
              as legendary as the unicorns.</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="orange">
    <section class="the-story-wrapper">
      <div class="the-story">
        <div class="story-header">
          <h2>The Plentiful Unicorns and the Magic Unicorns</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>Thousands of years ago, the unicorns were plentiful, their elegant forms and gentle spirits a common
              sight
              across the rolling meadows and ancient forests. However, not all unicorns were destined for greatness.
              Among
              the many, there were a select few, chosen by fate and the moon itself, to become the Magic Unicorns.</p>
          </div>
          <div class="story-paragraph">
            <p>The story of these extraordinary beings begins not on the earth but on the moon. The moon of Elysium was
              no
              ordinary celestial body; it was a place of mystic energies and ethereal beauty. It was said that under the
              silvery light of a full moon, the lunar surface would come alive with shimmering patterns, like an
              intricate
              dance of light and shadow. It was here, in this otherworldly cradle, that the Magic Unicorns were born.
            </p>
          </div>
        </div>
      </div>
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieSpiritContainer></div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="yellow">
    <section class="the-story-wrapper">
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieFlowerOpenContainer></div>
      </div>
      <div class="the-story">
        <div class="story-header">
          <h2>Birth of the Magic Unicorns on the Moon</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>Legend has it that these unicorns hatched from eggs laid in the moon’s craters. These eggs were no
              ordinary
              eggs; they were infused with the moon's magic, absorbing its mystical properties over millennia. Only the
              bravest and most resilient unicorns, those who had proven their worth through countless trials and
              tribulations on Elysium, were granted the honor of laying their eggs on the moon.</p>
          </div>
          <div class="story-paragraph">
            <p>The conditions on the moon were harsh, with frigid temperatures and a lack of atmosphere. Yet, it was
              precisely these conditions that made the eggs so special. The moon’s magic was most potent in such an
              environment, and the unicorns that emerged from these eggs were imbued with powers far beyond those of
              their
              earthbound kin. These newborns, known as Moonborn Unicorns, possessed a unique gift – a Magic Horn.</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="green">
    <section class="the-story-wrapper">
      <div class="the-story">
        <div class="story-header">
          <h2>The Magic Horn and Its Powers</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>The Magic Horn was no ordinary appendage; it was a conduit of the moon’s energy, a source of limitless
              fortune and luck. With their Magic Horns, the Moonborn Unicorns could harness the moon’s power, drawing
              strength and vitality from its light. This power allowed them to traverse the cosmos, bringing prosperity
              and
              happiness to the creatures they encountered on distant planets.</p>
          </div>
          <div class="story-paragraph">
            <p>The Moonborn Unicorns were not just born into greatness; they earned it through their deeds. Each unicorn
              underwent a series of rigorous tests on Elysium before being chosen to lay their egg on the moon. These
              tests
              were designed to assess their bravery, wisdom, and compassion. Only those who demonstrated unwavering
              courage
              in the face of danger, profound wisdom in solving complex dilemmas, and boundless compassion for all
              living
              beings were deemed worthy.</p>
          </div>
        </div>
      </div>
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieMagicRotationContainer></div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="blue">
    <section class="the-story-wrapper">
      <div class="the-story full-width">
        <div class="story-header">
          <h2>Selene's Journey and Vision</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>One such unicorn was named Selene. Selene was known throughout Elysium for her indomitable spirit and her
              unyielding dedication to the well-being of others. She had faced many perils, from battling fierce dragons
              to
              navigating treacherous terrains, always emerging victorious not through brute strength, but through her
              wit
              and
              her unwavering belief in the goodness of others.</p>
          </div>
          <div class="story-paragraph">
            <p>Selene's journey to the moon began with a vision. One night, as she lay beneath the starry sky, she
              dreamt
              of
              a
              celestial being bathed in lunar light. This being, a guardian of the moon, spoke to her in a voice like
              the
              tinkling of distant bells. "Selene," the guardian said, "you have been chosen. The moon awaits your
              presence,
              for it is there that your true destiny lies."</p>
          </div>
          <div class="story-paragraph">
            <p>Upon waking, Selene felt an unexplainable pull towards the heavens. She knew that her time had come.
              Guided
              by
              the vision, she made her way to the sacred glade where the elders of her herd resided. The elders, wise
              and
              ancient unicorns, recognized the significance of her dream. They bestowed upon her their blessings and,
              with
              great ceremony, sent her on her way.</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="indigo">
    <section class="the-story-wrapper">
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieRotatingContainer></div>
      </div>
      <div class="the-story">
        <div class="story-header">
          <h2>Selene's Trials and the Portal</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>The journey to the moon was perilous. Selene had to navigate through the enchanted forest of Illusia,
              where
              time and space were distorted, and reality itself seemed to bend. She encountered many challenges, from
              mischievous sprites that tried to lead her astray to shadowy figures that whispered doubts into her ears.
              Yet,
              Selene remained steadfast, her resolve unshaken by the trials she faced.</p>
          </div>
          <div class="story-paragraph">
            <p>Upon reaching the highest peak in Elysium, known as the Pinnacle of Dreams, Selene found the ancient
              portal
              that connected their world to the moon. This portal, a shimmering gateway of light, had been created by
              the
              first Moonborn Unicorns as a means to travel between the realms. With a deep breath and a heart full of
              hope,
              Selene stepped through the portal.</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="violet">
    <section class="the-story-wrapper">
      <div class="the-story">
        <div class="story-header">
          <h2>Selene on the Moon</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>The moon’s surface was a stark contrast to the vibrant world she had known. It was a landscape of silvery
              dunes
              and shadowed craters, bathed in an otherworldly glow. Yet, despite its desolation, Selene felt a profound
              sense
              of peace. She knew she was where she was meant to be.</p>
          </div>
          <div class="story-paragraph">
            <p>Selene found a secluded crater, its rim lined with luminescent crystals that pulsed with the moon’s
              magic.
              Here, she laid her egg, a luminous orb that radiated with potential. As she watched over it, she felt the
              moon’s
              energy infusing the egg, nurturing the life within.</p>
          </div>
          <div class="story-paragraph">
            <p>Time passed, and Selene remained vigilant, her faith unwavering. One night, under the light of a rare
              lunar
              eclipse, the egg began to crack. From within emerged a unicorn unlike any other. This was Luna, Selene’s
              daughter, a true Moonborn Unicorn. Luna's horn sparkled with an inner light, a testament to the moon’s
              magic.
            </p>
          </div>
        </div>
      </div>
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieMoonContainer></div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="lightgreen">
    <section class="the-story-wrapper">
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieHeartContainer></div>
      </div>
      <div class="the-story">
        <div class="story-header">
          <h2>Luna's Growth and Destiny</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>As Luna grew, it became clear that she possessed the same qualities that had made Selene a legend. She
              was
              brave, wise, and compassionate, always ready to help those in need. With her Magic Horn, Luna could
              perform
              feats of wonder. She could heal the sick, bring prosperity to barren lands, and light up the darkest of
              nights
              with her mere presence.</p>
          </div>
          <div class="story-paragraph">
            <p>But Luna’s true destiny lay beyond Elysium. With the guidance of her mother, she learned to harness the
              full
              potential of her Magic Horn. She discovered that by focusing its power, she could create a bridge of light
              that
              spanned the cosmos, allowing her to travel to distant planets.</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="cyan">
    <section class="the-story-wrapper">
      <div class="the-story">
        <div class="story-header">
          <h2>Luna's Cosmic Journeys</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>On these planets, Luna found creatures in need of her aid. There were worlds shrouded in darkness, where
              despair had taken root. There were lands plagued by strife, where harmony seemed a distant dream. To all
              these
              places, Luna brought hope. Her presence was a beacon of light, a symbol of the moon’s enduring magic.</p>
          </div>
          <div class="story-paragraph">
            <p>With each journey, Luna’s legend grew. She became known as the Bringer of Fortune, the Harbinger of Hope.
              Her
              deeds were celebrated in songs and stories, passed down through generations. The creatures she helped
              never
              forgot the unicorn with the Magic Horn, whose kindness knew no bounds.</p>
          </div>
        </div>
      </div>
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieAidContainer></div>
      </div>
    </section>
  </div>

  <div class="panel" data-color="magenta">
    <section class="the-story-wrapper">
      <div class="lottie-container">
        <div class="lottie-apple-container" #lottieUnicornContainer></div>
      </div>
      <div class="the-story">
        <div class="story-header">
          <h2>Selene's Pride and the Legacy of the Magic-Horn</h2>
        </div>
        <div class="story-container">
          <div class="story-paragraph">
            <p>Back on Elysium, Selene watched with pride as her daughter fulfilled her destiny. She knew that Luna was
              just
              the first of many. The Moonborn Unicorns, with their Magic Horns, would continue to emerge, each one
              destined
              to
              make the universe a better place.</p>
          </div>
          <div class="story-paragraph">
            <p>And so, the legend of the Magic-Horn was born. It was a tale of courage, of hope, and of the boundless
              potential that lies within each of us. It reminded the creatures of Elysium, and all the worlds touched by
              the
              Moonborn Unicorns, that even in the darkest of times, there is always a light that can guide us to a
              brighter
              future.</p>
          </div>
        </div>
      </div>
    </section>
  </div>