<div class="landing-page-wrapper">
  <div class="banner-box">
    <div class="banner-text">
      <h1>The Magical Portal</h1>
      <h1>Selene's Journey to the Moon</h1>
    </div>
  </div>

  <div class="mint-wrapper">
    <div class="mint-container">
      <div class="mint-details">
        <div class="actions">
          <button class="button-square-pink" (click)="openShopWithLunaFilter()">Show and Mint NFT</button>
        </div>
        <div class="nft-supply">
          <h2>Total NFTs Minted: {{ totalSupply }} of 235</h2>
        
          
          <button mat-icon-button (click)="fetchNftSupply()">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <canvas class="qr-sample-code" #qrCanvas></canvas>

    </div>
  </div>


  <section class="luna-wrapper">
    <div class="luna-container">
      <h2>Support Selene's Celestial Journey</h2>
      <p>Join Selene, the legendary unicorn, on her sacred journey through the mystical rainbow portal to the moon, where she will give birth to Luna, the first Moonborn Unicorn. By purchasing Selene's exclusive NFTs, you become a part of this magical story, supporting her quest to bring Luna into the world, filled with hope and cosmic magic.</p>
      <p>This NFT collection is dedicated to Selene’s journey, capturing the momentous event of Luna's birth and the magical powers that come with it.</p>
      <p>This exclusive V1 collection includes 235 unique versions of Selene's celestial journey, each one available for minting. Don’t miss your chance to own a part of this limited edition and be a part of Luna’s origin story.</p>
    </div>
  </section>

  <section class="features-wrapper">
    <div class="features-elements-wrapper">
      <div class="features-container">
        <div class="feature">
          <i class="fas fa-paint-brush"></i>
          <h2>Hand-Drawn Designs</h2>
          <p>Each Magic Horn NFT is uniquely designed by talented artists, ensuring no two are alike.</p>
        </div>
        <div class="feature">
          <i class="fas fa-star"></i>
          <h2>Rarity Levels</h2>
          <p>Discover our varied rarity levels, from Common to Legendary, adding an element of excitement to every collection.</p>
        </div>
        <div class="feature">
          <i class="fas fa-gem"></i>
          <h2>Limited Editions</h2>
          <p>With limited editions available, owning a Magic Horn NFT is a rare and exclusive experience.</p>
        </div>
      </div>
      <div class="features-container">
        <div class="feature">
          <i class="fas fa-palette"></i>
          <h2>Unique Artwork</h2>
          <p>Each Magic Horn NFT is a one-of-a-kind piece of art, meticulously designed by talented artists to ensure exclusivity.</p>
        </div>
        <div class="feature">
          <i class="fas fa-highlighter"></i> <!-- Changed icon to highlighter -->
          <h2>High-Quality Designs</h2>
          <p>Our NFTs feature high-resolution, visually stunning designs that stand out in any digital collection.</p>
        </div>
        <div class="feature">
          <i class="fas fa-lock"></i> <!-- Changed icon to lock -->
          <h2>Blockchain Security</h2>
          <p>Magic Horn NFTs are secured on the blockchain, guaranteeing authenticity and ownership.</p>
        </div>
      </div>
    </div>
  </section>
  
  <section class="luna-wrapper">
    <div class="luna-container">
      <h2>Support Luna's Journey</h2>
      <p>Meet Luna, the first Moonborn Unicorn, whose mission is to bring fortune and happiness across the cosmos. By purchasing Luna's exclusive NFTs, you not only own a piece of this magical story but also support her quest to spread goodwill and prosperity. Each NFT is crafted with Luna's essence, carrying the magic of the moon and the spirit of hope.</p>
      <div class="actions">
        <button class="button-square-pink" routerLink="/shop">Explore Luna's NFTs</button>
      </div>
    </div>
  </section>

  <section class="nft-tiles-wrapper">
    <div class="nft-tiles-header">
      possible outcome of Baby Luna NFT
    </div>
    <div class="nft-tiles-container">
      <button (click)="fetchSentTransactions()">Fetch Sent Transactions</button>


      <div>coming soon</div>
      <!--
      <div class="nft-tile" *ngFor="let nft of nfts">
        <img [src]="nft.image" alt="NFT Image">
        <div class="nft-info">
          <p>Purchase Date: {{ nft.purchaseDate }}</p>
          <p>Last Trading Date: {{ nft.lastTradingDate }}</p>
          <p>Current Value: {{ nft.currentValue }}</p>
        </div>
      </div>
      -->
    </div>
  </section>


  <section class="get-now-container">
    <h2>Join Luna's Journey Now!</h2>
    <p>Don't miss the opportunity to be part of this magical adventure. By minting your exclusive Baby Luna NFT, you
      support Luna's mission and own a piece of this unique story. Secure your NFT today and join our community of
      supporters!</p>
    <div class="button-container">
      <button class="button-square-pink" (click)="openShopWithLunaFilter()">Get/Mint Your NFT Now</button>
    </div>
  </section>
</div>