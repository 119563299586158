// store/actions/cart.actions.ts
import { createAction, props } from '@ngrx/store';
import { Product } from '../models/product.model';
import { CartItem } from '../models/cart-item.model';

export const addToCart = createAction(
  '[Cart] Add To Cart',
  props<{ product: Product }>()
);

export const setSinglePurchaseRestriction = createAction(
  '[Cart] Set Single Purchase Restriction',
  props<{ productId: string }>()
);
export const initializeCart = createAction('[Cart] Initialize Cart', props<{ items: CartItem[] }>());
export const removeFromCart = createAction('[Cart] Remove From Cart', props<{ productId: string }>());
export const clearCart = createAction('[Cart] Clear Cart');
export const checkout = createAction(
  '[Cart] Checkout',
  props<{ totalAmount: number, email: string, userDetails: any, userAddress: any, products: { productUid: string, purchasedAmount: number }[], currency: string }>()
);
export const checkoutSuccess = createAction('[Cart] Checkout Success', props<{ clientSecret: string }>());
export const checkoutFailure = createAction('[Cart] Checkout Failure', props<{ error: any }>());
export const incrementQuantity = createAction('[Cart] Increment Quantity', props<{ productId: string }>());
export const decrementQuantity = createAction('[Cart] Decrement Quantity', props<{ productId: string }>());
export const selectColor = createAction('[Cart] Select Color', props<{ productId: string, color: { name: string, topColor: string, bottomColor?: string } }>());
