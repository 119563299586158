import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { cartReducer, CartState } from './reducers/cart.reducer';
import { languageReducer } from './reducers/language.reducer';
import { CartEffects } from './effects/cart.effects';

import { localStorageSyncReducer } from './reducers/local-storage-sync.reducer';

export interface AppState {
  cart: CartState;
  language: string;
}

export const rootReducer: ActionReducerMap<AppState> = {
  cart: cartReducer,
  language: languageReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

export const rootEffects = [CartEffects];
