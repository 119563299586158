<div class="shop-wrapper">
  <div class="shop-header">
    Available NFTs and more
  </div>

  <div class="filter-wrapper">
    <form [formGroup]="filterForm" class="filter-container">
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput formControlName="search" placeholder="Search by description">
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Product Type</mat-label>
        <mat-select formControlName="productType">
          <mat-option value="----">----</mat-option>
          <mat-option *ngFor="let type of productTypes" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Product Collection</mat-label>
        <mat-select formControlName="productCollection">
          <mat-option value="----">----</mat-option>
          <mat-option *ngFor="let collection of productCollections" [value]="collection">{{ collection }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="resetFilters()" [disabled]="isFiltersReset()">
        <i class="fas fa-undo"></i>
      </button>
    </form>
  </div>
  
  

  <div class="shop-container">
    <div class="flex-container">
      <div *ngFor="let product of filteredProducts; trackBy: trackByProductId" class="flex-item product-card">
        <div class="product-header">
          <div>{{ product.productDetails.productName }}</div>
        </div>
  
        <div class="product-content">
          <div class="image-container" (mouseenter)="hoveredProduct = product.productDetails.productUid" (mouseleave)="hoveredProduct = null">
            <div *ngIf="product.images.length; else noImageMessage">
              <img [src]="getImageUrl(product.images[hoveredProduct === product.productDetails.productUid && product.images.length > 1 ? 1 : 0].imageURL)" 
                   alt="{{ product.productDetails.productName }}">
            </div>
            <ng-template #noImageMessage>
              <p>No image available</p>
            </ng-template>
          </div>
  
          <div class="description">
            <p>{{ product.productDetails.productDescription }}</p>
          </div>
        </div>
  
        <div class="bottom-meta-bar">
          <div class="meta">
            <div class="price">
              <p [innerHTML]="getProductPrice(product)"></p>
            </div>
            <div class="tax" *ngIf="product.cost.tax !== 'none'">
              <ng-container *ngIf="product.cost.tax === 'incl'">incl. Tax</ng-container>
              <ng-container *ngIf="product.cost.tax === 'excl'">excl. Tax</ng-container>
            </div>
          </div>
  
          <div class="button-container">
            <button class="button-blue" (click)="viewProductDetails(product.productDetails.productUid)">
              View Details
            </button>
  <!--          <button class="button-blue" (click)="addToCart(product)" [disabled]="(isProductInCart(product) | async) || (hasSinglePurchaseRestriction(product) | async)">
              <i class="fas fa-shopping-cart"></i>
            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div #paymentRef></div>
