import { Product } from '../../../store/models/product.model';

const NFT_BABY_LUNA_RARE: Product = {
  productDetails: {
    productUid: 'KxWpV3bNr8QzHd7LgFc',
    productNumber: 'NFT-003',
    productName: 'NFT Baby Luna Rare',
    productDescription: 'Limited edition Baby Luna NFT with intricate designs, coveted by serious collectors.',
    productFeatures: `
    Exquisite digital art that captures the rare essence of the Baby Luna collection, featuring intricate details and enhanced attributes. This NFT is secured by blockchain-verified ownership, ensuring it is an authentic and unique digital asset with a traceable history on the Ethereum network. The rare rarity level significantly increases its value and appeal in secondary markets, where collectors seek out rare and limited items to diversify and enhance their portfolios. The Baby Luna Rare NFT offers a perfect blend of scarcity, artistic detail, and investment potential, making it a prized asset for discerning collectors.
  `,
      productInformation: `
    The Rare Baby Luna NFT takes the collection to the next level with a significantly more refined and detailed design, reflecting a deeper connection to the lunar theme that defines the Baby Luna series. This NFT is crafted with enhanced attributes and a higher level of detail that resonates with seasoned collectors and those who appreciate the rarity and the art of digital creation. With a more restricted supply, the Rare Baby Luna is a prized asset, offering a unique opportunity to own a piece of the collection that combines beauty, rarity, and potential long-term value. Its scarcity and intricate design make it a standout choice for collectors aiming to build a more valuable and exclusive NFT portfolio.
  `,
      productStart: new Date(),
      productPublished: false,
  },
  productSpecifics: {
    productType: 'nft',
    fileName: 'nft-baby-luna-rare',
    productCollection:'Luna Baby',
    firestoreCollection: 'NFT_LunaBaby',
    affiliateUrl: '',
    smartContractDetails: `
  This Rare Baby Luna NFT operates under a sophisticated ERC-721 smart contract designed to manage limited-supply, high-value digital assets. The contract enforces a mint fee of 0.3 ETH and ensures each NFT is securely stored and verified on the Ethereum blockchain. Rare NFTs are distinguished by enhanced attributes and a more limited supply, making them highly sought after. The contract also includes advanced features to ensure that these NFTs can be easily transferred and traded, while their rarity is consistently preserved through carefully managed token availability.
`,

  },
  cost: {
    priceAmountSingleItem: 0.3,
    priceCurrency: 'ETH',
    tax: 'none',
    tieredPricing: [],
  },
  images: [
    { imageURL: 'babyunicorn.webp', imageName: 'Rare Baby Luna NFT Image' },
  ],
  colors: [],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered instantly on purchase' },
    { icon: 'fas fa-truck', info: 'Blockchain verified' },
    { icon: 'fas fa-undo-alt', info: 'No refunds' },
  ],
};

export default NFT_BABY_LUNA_RARE;
