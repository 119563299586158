import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-registration-complete',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent implements OnInit {
  email!: string;
  userDetails!: any;
  userAddress!: any;
  recoveryUID!: string;
  userAccess!: any;

  constructor(private route: ActivatedRoute, private clipboard: Clipboard) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParams['email'] || 'Not available';
    this.userDetails = JSON.parse(this.route.snapshot.queryParams['userDetails'] || '{}');
    this.userAddress = JSON.parse(this.route.snapshot.queryParams['userAddress'] || '{}');
    this.recoveryUID = this.route.snapshot.queryParams['recoveryUID'] || 'Not available';
    this.userAccess = JSON.parse(this.route.snapshot.queryParams['userAccess'] || '{}');
  }

  copyToClipboard(value: string): void {
    this.clipboard.copy(value);
    alert('Copied to clipboard');
  }
}
