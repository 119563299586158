import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { Store } from '@ngrx/store';
import { map, Observable, of, take } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../../store/root';
import { CartItem } from '../../../store/models/cart-item.model';
import { selectCartItems, selectSinglePurchaseRestriction } from '../../../store/selectors/cart.selectors';
import { ProductService } from '../../../services/shop/product-local.service';
import { Product } from '../../../store/models/product.model';
import { addToCart, setSinglePurchaseRestriction } from '../../../store/actions/cart.actions';

@Component({
  selector: 'app-shop',
  standalone: true,
  imports: [
    CommonModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconButton,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopComponent implements OnInit {
  filterForm: FormGroup;
  products: Product[] = [];
  filteredProducts: Product[] = []; // Add this to hold the filtered products
  productTypes: string[] = ['NFT', 'Merchandise'];
  productCollections: string[] = ['Luna Baby (coming soon)', 'Rainbow Portal'];
  cartItems$: Observable<CartItem[]>;
  hoveredProduct: string | null = null;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.filterForm = this.fb.group({
      search: [''],
      productType: ['----'],
      productCollection: ['----'],
    });
    this.cartItems$ = this.store.select(selectCartItems);
  }

  ngOnInit(): void {
    this.loadProducts();

    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });

    this.route.queryParams.subscribe(params => {
      if (params['productType']) {
        this.filterForm.get('productType')?.setValue(params['productType']);
      }
      if (params['productCollection']) {
        this.filterForm.get('productCollection')?.setValue(params['productCollection']);
      }
    });
  }

  loadProducts(): void {
    this.productService.getAllProducts().subscribe((products) => {
      this.products = products;
      this.applyFilters();
      this.cdr.markForCheck();
    });
  }

  applyFilters(): void {
    const { search, productType, productCollection } = this.filterForm.value;
  
    this.filteredProducts = this.products.filter(product => {
      if (!product.productDetails.productPublished) {
        return false;
      }
  
      const matchesSearch = search ? 
        product.productDetails.productDescription.toLowerCase().includes(search.toLowerCase()) : true;
      
      const matchesType = productType !== '----' ? 
        product.productSpecifics.productType.toLowerCase() === productType.toLowerCase() : true;
      
      const matchesCollection = productCollection !== '----' ? 
        product.productSpecifics.productCollection?.toLowerCase() === productCollection.toLowerCase() : true;
      
      return matchesSearch && matchesType && matchesCollection;
    });
  
    this.cdr.markForCheck();
  }

resetFilters(): void {
  this.filterForm.setValue({
    search: '',
    productType: '----',
    productCollection: '----'
  });
  this.applyFilters();
}

isFiltersReset(): boolean {
  const { search, productType, productCollection } = this.filterForm.value;
  return !search && productType === '----' && productCollection === '----';
}


  getProductPrice(product: Product): string {
    if (product.cost.freeOfCharge) {
      return '<i class="fas fa-gift"></i> Free';
    } else {
      return `${product.cost.priceAmountSingleItem} ${product.cost.priceCurrency || 'EUR'}`;
    }
  }

  getImageUrl(imageURL: string): string {
    return `/assets/images/shop/${imageURL}`;
  }

  trackByProductId(index: number, product: Product): string {
    return product.productDetails.productUid;
  }

  viewProductDetails(productId: string): void {
    this.router.navigate(['/shop/product-details', productId]);
  }

  addToCart(product: Product): void {
    if (product.singlePurchaseOnly) {
      this.store.select(selectCartItems).pipe(
        map(cartItems => cartItems.some(item => item.product.productDetails.productUid === product.productDetails.productUid)),
        take(1)
      ).subscribe(isInCart => {
        if (!isInCart) {
          this.store.dispatch(addToCart({ product }));
          this.store.dispatch(setSinglePurchaseRestriction({ productId: product.productDetails.productUid }));
        }
      });
    } else {
      this.store.dispatch(addToCart({ product }));
    }
  }

  hasSinglePurchaseRestriction(product: Product): Observable<boolean> {
    return this.store.select(selectSinglePurchaseRestriction(product.productDetails.productUid));
  }

  isProductInCart(product: Product): Observable<boolean> {
    if (product.productSpecifics.productType !== 'nft') {
      return of(false);
    }
    return this.store.select(selectCartItems).pipe(
      map(cartItems => cartItems.some(item => item.product.productDetails.productUid === product.productDetails.productUid))
    );
  }
}
