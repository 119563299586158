import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { BlogIndex, BlogPostMetadata, BlogPost } from '../../store/models/blog.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BlogPostCreateService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  getBlogMetadata(slug: string): Observable<BlogPostMetadata> {
    const lang = this.translate.currentLang || 'en';
    const metadataPath = `assets/blog/${slug}/${lang}.json`;
  
    return this.http.get<BlogPostMetadata>(metadataPath).pipe(
      catchError((error: HttpErrorResponse) => {
        if (lang !== 'en') {
          const englishMetadataPath = `assets/blog/${slug}/en.json`;
          return this.http.get<BlogPostMetadata>(englishMetadataPath).pipe(
            catchError((englishError: HttpErrorResponse) => {
              console.error(`[BLOGPOSTCREATESERVICE] Failed to load blog metadata from ${englishMetadataPath}:`, englishError.message);
              return of({} as BlogPostMetadata);
            })
          );
        } else {
          console.error(`[BLOGPOSTCREATESERVICE] Failed to load blog metadata from ${metadataPath}:`, error.message);
          return of({} as BlogPostMetadata);
        }
      })
    );
  }
  
  getMarkdownContent(slug: string): Observable<string> {
    const lang = this.translate.currentLang || 'en';
    const markdownPath = `assets/blog/${slug}/${lang}.md`;
  
    return this.http.get(markdownPath, { responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (lang !== 'en') {
          const englishMarkdownPath = `assets/blog/${slug}/en.md`;
          return this.http.get(englishMarkdownPath, { responseType: 'text' }).pipe(
            catchError((englishError: HttpErrorResponse) => {
              console.error(`[BLOGPOSTCREATESERVICE] Failed to load markdown for ${lang} at ${englishMarkdownPath}`, englishError);
              return of('Content not available.');
            })
          );
        } else {
          console.error(`[BLOGPOSTCREATESERVICE] Failed to load markdown for ${lang} at ${markdownPath}`, error);
          return of('Content not available.');
        }
      })
    );
  }
  

  getBlogPost(slug: string): Observable<BlogPost> {
    return this.http.get<BlogIndex[]>('assets/blog/index.json').pipe(
      map(posts => posts.find(post => post.slug === slug)),
      switchMap(indexData => {
        if (!indexData) {
          console.error("Blog post not found for slug:", slug);
          return throwError(() => new Error("Blog post not found"));
        }
        return this.getBlogMetadata(slug).pipe(
          switchMap(metadata => {
            if (!metadata) {
              console.error("Metadata not found for blog post:", slug);
              return throwError(() => new Error("Metadata not found"));
            }
            return this.getMarkdownContent(slug).pipe(
              catchError(error => {
                console.error("Error fetching markdown content for blog post:", slug, error);
                return throwError(() => new Error("Error fetching markdown content"));
              }),
              map(content => {
                if (!content) {
                  console.error("Content not found for blog post:", slug);
                  throw new Error("Content not found");
                }
                return {
                  ...indexData,
                  ...metadata,
                  content,
                  imagePath: `assets/blog/${slug}/${indexData.imageName}`
                };
              }),
            );
          }),
        );
      }),
    );
  }

  getBlogPosts(): Observable<BlogPost[]> {
    return this.http.get<BlogIndex[]>('assets/blog/index.json').pipe(
      switchMap(posts => forkJoin(posts.map(post =>
        this.getBlogMetadata(post.slug).pipe(
          switchMap(metadata => this.getMarkdownContent(post.slug).pipe(
            map(content => ({
              ...post,
              ...metadata,
              content,
              imagePath: `assets/blog/${post.slug}/${post.imageName}`
            }))
          )),
          catchError(error => {
            console.error(`[BLOGPOSTCREATESERVICE] Failed to load post for slug: ${post.slug}, error: ${error}`);
            return of(null);
          })
        ))
      )),
      map(posts => posts.filter(post => post !== null) as BlogPost[])
    );
  }


  updateLanguage(lang: string): Observable<BlogPost[]> {
    // Update the language in the translation service
    this.translate.use(lang);
    // Reload blog posts for the new language
    return this.getBlogPosts();
  }
}
