import { Product } from '../../../store/models/product.model';

const NFT_BABY_LUNA_LEGENDARY: Product = {
  productDetails: {
    productUid: 'Lj8nPtXcWqF9vYzAr6Bs',
    productNumber: 'NFT-005',
    productName: 'NFT Baby Luna Legendary',
    productDescription: 'Ultimate Baby Luna NFT, extremely rare with unmatched features for elite collectors.',
    productFeatures: `
    The pinnacle of digital art within the Baby Luna collection, the Legendary NFT is a truly unique masterpiece featuring unparalleled attributes and the most intricate design in the series. Blockchain-verified ownership provides an immutable record of authenticity and exclusivity, secured on the Ethereum network. As the rarest and most coveted item in the collection, the Legendary Baby Luna NFT holds immense value in secondary markets, where its scarcity and exceptional craftsmanship make it a cornerstone asset for elite collectors. This NFT is the ultimate symbol of distinction and prestige, offering not just ownership of a digital asset, but a legacy piece that signifies the highest level of achievement in the NFT space.
  `,
      productInformation: `
    The Legendary Baby Luna NFT is the pinnacle of the Baby Luna collection, offering the most exclusive and exquisite design, reserved for the most discerning collectors. This NFT embodies the essence of rarity, with features and details that are unparalleled within the series. The Legendary Baby Luna is a highly coveted digital asset, with an extremely limited supply that ensures its position as a cornerstone of any serious NFT collection. It represents the ultimate achievement in digital art and ownership, appealing to collectors who seek the highest level of exclusivity and the greatest potential for long-term appreciation. As the crown jewel of the Baby Luna collection, this NFT is not just a collectible, but a legacy piece that signifies the holder's elite status in the NFT community.
  `,
      productStart: new Date(),
      productPublished: false,
  },
  productSpecifics: {
    productType: 'nft',
    fileName: 'nft-baby-luna-legendary',
    productCollection:'Luna Baby',
    firestoreCollection: 'NFT_LunaBaby',
    affiliateUrl: '',
    smartContractDetails: `
  The Legendary Baby Luna NFT is supported by a highly advanced ERC-721 smart contract, designed for the most exclusive and rare digital assets. Priced at 2 ETH, this contract ensures the highest level of security and immutability for Legendary NFTs on the Ethereum blockchain. These NFTs are the pinnacle of rarity and value within the collection, with extremely limited supply and exclusive attributes. The smart contract includes cutting-edge features to manage ownership, transfers, and royalties, ensuring that these NFTs remain prestigious and valuable within the marketplace. The contract also facilitates seamless trading while preserving the integrity and rarity of each Legendary token.
`,

  },
  cost: {
    priceAmountSingleItem: 2,
    priceCurrency: 'ETH',
    tax: 'none',
    tieredPricing: [],
  },
  images: [
    { imageURL: 'babyunicorn.webp', imageName: 'Legendary Baby Luna NFT Image' },
  ],
  colors: [],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered instantly on purchase' },
    { icon: 'fas fa-truck', info: 'Blockchain verified' },
    { icon: 'fas fa-undo-alt', info: 'No refunds' },
  ],
};

export default NFT_BABY_LUNA_LEGENDARY;
