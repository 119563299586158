import { Product } from '../../../store/models/product.model';
import contractAbi from './nft-rainbow-portal-contract-abi.json';

const NFT_RAINBOW_PORTAL: Product = {
  productDetails: {
    productUid: 'o7LnWpXqT8YkVr4Hc5F',
    productNumber: 'NFT-003',
    productName: 'NFT Rainbow Portal',
    productDescription: 'A mystical NFT that captures the essence of a rainbow portal, offering a unique blend of colors and mysticism.',
    productFeatures: `
      The Rainbow Portal NFT stands as a beacon of digital artistry, where the vibrant hues of the rainbow meet the mystical essence of a portal to another dimension. This NFT is more than just a collectible; it's a gateway to a world of imagination, symbolizing hope, diversity, and unity through its spectrum of colors. The Ethereum-backed ownership ensures that this artwork is not only visually captivating but also securely held in your digital collection, making it a valuable and timeless piece in the evolving world of NFTs.
    `,
    productInformation: `
      The Rainbow Portal NFT is a digital masterpiece designed for collectors who appreciate the intersection of art and mysticism. Its swirling colors and intricate design elements create a visually stunning experience, while its rarity and blockchain verification make it a secure investment. As part of the Rainbow Portal collection, this NFT is one of the few pieces that truly encapsulate the spirit of digital art in the age of blockchain. Collectors will find that the Rainbow Portal NFT not only enhances their digital gallery but also serves as a conversation starter and a unique representation of the evolving world of art and technology.
    `,
    productStart: new Date(),
    productPublished: true,
  },
  productSpecifics: {
    productType: 'nft',
    fileName: 'nft-rainbow-portal',
    productCollection: 'Rainbow Portal',
    firestoreCollection: 'NFT_RainbowPortal',
    affiliateUrl: '',
    smartContractDetails: `
      The Rainbow Portal NFT operates on a secure ERC-721 smart contract, ensuring that each piece is unique, verifiable, and easily transferable. The contract is designed to handle the minting process seamlessly, with a mint fee of 0.15 ETH. Collectors can rest assured that their Rainbow Portal NFT is protected by blockchain technology, providing a permanent record of ownership that is both transparent and immutable. This NFT is a limited edition, adding to its value and appeal in the world of digital art.
    `,
    smartContractAdress:'0xA66c2D020f7f87063F51646F39738746FEcb636b',
    smartContractABI: contractAbi.abi,
    cidAssingment: 'random',
    baseURI:'',
    cidNumber:'',
    cidGroup:'',

  },
  cost: {
    priceAmountSingleItem: 0.005,
    priceCurrency: 'ETH',
    tax: 'none',
  },
  images: [
    { imageURL: 'rainbow-portal-sample.webp', imageName: 'Rainbow Portal NFT Image' },
  ],
  imageText:'sample nft, all nfts will have a random rainbow pattern',
  colors: [],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered instantly on purchase' },
    { icon: 'fas fa-palette', info: 'Unique color blend' },
    { icon: 'fas fa-undo-alt', info: 'No refunds' },
  ],
};

export default NFT_RAINBOW_PORTAL;
