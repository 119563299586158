// snackbar.service.ts
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) {}

  showCustomSnackbar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: { message },
      duration: 5000
    });
  }
}
