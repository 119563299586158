import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

if (environment.production) {
  enableProdMode();
}

const app = initializeApp(environment.firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(environment.firebaseACKeyH),
  isTokenAutoRefreshEnabled: true
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));