import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ProductService } from '../../../services/shop/product-local.service';
import { Product } from '../../../store/models/product.model';
import { AppState } from '../../../store/root';
import { addToCart, selectColor, setSinglePurchaseRestriction } from '../../../store/actions/cart.actions';
import { CartItem } from '../../../store/models/cart-item.model';
import { Observable, of } from 'rxjs';
import { selectSinglePurchaseRestriction, selectCartItems, selectSelectedColor, selectProductQuantity } from '../../../store/selectors/cart.selectors';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarService } from '../../../components/snack-bar/snack-bar.service';
import { Web3ModalComponent } from './web3-modal/web3-modal.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    FormsModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatTabGroup,
    Web3ModalComponent
  ]
})
export class ProductDetailsComponent implements OnInit {
  product: Product | undefined;
  cartItems$: Observable<CartItem[]>;
  selectedImageIndex = 0;
  quantity: number = 1;
  selectedColor$: Observable<{ name: string, topColor: string, bottomColor?: string } | null>;
  showTieredPricingMessage = false;
  cartItems: CartItem[] = [];

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private store: Store<AppState>,
    private router: Router,
    private snackbarService: SnackbarService
  ) {
    this.cartItems$ = this.store.select(selectCartItems);
    const productId = this.route.snapshot.paramMap.get('productId')!;
    this.selectedColor$ = this.store.select(selectSelectedColor(productId));
  }

  ngOnInit(): void {
    const productId = this.route.snapshot.paramMap.get('productId')!;
    this.productService.getProduct(productId).subscribe((product) => {
      this.product = product;

      if (this.product && !this.product.productDetails.productPublished) {
        this.snackbarService.showCustomSnackbar('Product not published');
        this.router.navigate(['/shop']);
        return;
      }
    });
  
    this.cartItems$.subscribe(items => {
      this.cartItems = items ?? [];
    });
  
    this.store.select(selectProductQuantity(productId)).subscribe(quantity => {
      this.quantity = quantity;
    });
  }

  getTotalProductPrice(product?: Product, quantity: number = 1): string {
    if (!product) return '';

    if (product.cost?.freeOfCharge) {
      return '<i class="fas fa-gift"></i> Free';
    }
    if (product.cost?.tieredPricing) {
      let pricePerItem = 0;
      for (const tier of product.cost.tieredPricing) {
        if (quantity >= tier.tier) {
          pricePerItem = tier.price;
        }
      }
      if (pricePerItem > 0) {
        return `${(pricePerItem * quantity).toFixed(2)} ${product.cost.priceCurrency || 'EUR'} for ${quantity} items`;
      }
    }
    if (product.cost?.priceAmountSingleItem) {
      return `${(product.cost.priceAmountSingleItem * quantity).toFixed(2)} ${product.cost.priceCurrency || 'EUR'}`;
    }

    return '';
  }

  getProductPrice(product?: Product, quantity: number = 1): string {
    if (!product) return '';

    if (product.cost?.freeOfCharge) {
      return '<i class="fas fa-gift"></i> Free';
    }

    if (product.cost?.tieredPricing && product.cost.tieredPricing.length > 0) {
      let pricePerItem = 0;
      for (const tier of product.cost.tieredPricing) {
        if (quantity >= tier.tier) {
          pricePerItem = tier.price;
        }
      }
      if (pricePerItem > 0) {
        return `${pricePerItem.toFixed(2)} ${product.cost.priceCurrency || 'EUR'} per item`;
      }
    }

    if (product.cost?.priceAmountSingleItem) {
      return `${product.cost.priceAmountSingleItem.toFixed(3)} ${product.cost.priceCurrency || 'EUR'} per Item`;
    }

    return '';
  }

  getImageUrl(imageURL: string): string {
    return `/assets/images/shop/${imageURL}`;
  }

  addToCart(product: Product): void {
    if (product.singlePurchaseOnly) {
      this.store.select(selectCartItems).pipe(
        map(cartItems => cartItems.some(item => item.product.productDetails.productUid === product.productDetails.productUid)),
        take(1)
      ).subscribe(isInCart => {
        if (!isInCart) {
          this.store.dispatch(addToCart({ product }));
          this.store.dispatch(setSinglePurchaseRestriction({ productId: product.productDetails.productUid }));
          this.snackbarService.showCustomSnackbar('Product added to cart successfully.');
        } else {
          this.snackbarService.showCustomSnackbar('This product is already in your cart.');
        }
      });
    } else {
      this.store.dispatch(addToCart({ product }));
      this.snackbarService.showCustomSnackbar('Product added to cart successfully.');
    }
  }
  
  hasSinglePurchaseRestriction(product: Product): Observable<boolean> {
    return this.store.select(selectSinglePurchaseRestriction(product.productDetails.productUid));
  }

  isProductInCart(product: Product): Observable<boolean> {
    if (product.productSpecifics.productType !== 'online_course') {
      return of(false);
    }
    return this.store.select(selectCartItems).pipe(
      map(cartItems => cartItems.some(item => item.product.productDetails.productUid === product.productDetails.productUid))
    );
  }

  selectImage(index: number): void {
    this.selectedImageIndex = index;
  }

  selectColor(color: { name: string, topColor: string, bottomColor?: string }): void {
    const productId = this.product?.productDetails.productUid;
    if (productId) {
      this.store.dispatch(selectColor({ productId, color }));
    }
  }

  decrementQuantity(): void {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  incrementQuantity(): void {
    this.quantity++;
  }

  openTieredPricingTab(tabGroup: MatTabGroup): void {
    const tieredPricingIndex = 3;
    tabGroup.selectedIndex = tieredPricingIndex;
  }

  navigateBack(): void {
    this.router.navigate(['/shop']);
  }
}
