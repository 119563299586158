// src/app/components/payment-refund-policy/payment-refund-policy.component.ts
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-payment-refund-policy',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './payment-refund-policy.component.html',
  styleUrls: ['./payment-refund-policy.component.scss']
})
export class RefundReturnPolicyComponent {}
