import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-mint-completed',
  templateUrl: './mint-completed.component.html',
  styleUrls: ['./mint-completed.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class MintCompletedComponent {
  transactionHash: string | null = null;
  from: string | null = null;
  to: string | null = null;
  value: string | null = null;
  tokenURIJson: string | null = null;
  jsonUrl: string | null = null;
  imageUrl: SafeUrl | null = null;
  docId: string | null = null;

  // Hardcoded values for development
  private readonly hardcodedTokenURIJson = "ipfs://QmTfQULDBqdTmpQdENjUJd66oHKGdZHVc6uV2U6F4qPZbA/magic_horn_rainbow_portal_1723668277712.json";
  private readonly hardcodedTokenURIImage = "ipfs://QmYGwUZSea9YiqnotuZ4r7ena8LAMxfYJdaTWt1ySzSQY1/magic_horn_rainbow_portal_1723668277712.png";

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state as {
      transactionHash: string;
      from: string;
      to: string;
      value: string;
      tokenURIJson: string;
      docId: string;
    };

    if (state) {
      this.transactionHash = state.transactionHash;
      this.from = state.from;
      this.to = state.to;
      this.value = state.value;
      this.tokenURIJson = state.tokenURIJson || this.hardcodedTokenURIJson;
      this.docId = state.docId;
    } else {
      // Use hardcoded values if no state is provided
      this.tokenURIJson = this.hardcodedTokenURIJson;
    }

    this.jsonUrl = this.tokenURIJson.replace('ipfs://', 'https://ipfs.io/ipfs/');
    this.fetchImageFromJson();
  }

  fetchImageFromJson(): void {
    const sanitizedUrl = this.hardcodedTokenURIImage.replace('ipfs://', 'https://ipfs.io/ipfs/');
    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
  }

  openJsonInTab(): void {
    if (this.jsonUrl) {
      window.open(this.jsonUrl, '_blank');
    }
  }

  openImageInTab(): void {
    if (this.imageUrl) {
      window.open(this.imageUrl.toString(), '_blank');
    }
  }

  copyToClipboard(url: string | SafeUrl | null): void {
    if (!url) {
      console.error('URL is null or undefined, cannot copy.');
      return;
    }

    const strUrl = url.toString();
    navigator.clipboard.writeText(strUrl).then(() => {
      console.log('URL copied to clipboard');
    });
  }

  navigateBackToShop(): void {
    this.router.navigate(['/shop']);
  }
}
