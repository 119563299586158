import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-feedback-carousel',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './feedback-carousel.component.html',
  styleUrls: ['./feedback-carousel.component.scss']
})
export class FeedbackCarouselComponent {
  reviews = [
    { name: "Alice Johnson", date: "Sep 15, 2024", text: "The uniqueness of each Magic Horn NFT is incredible! Every piece feels like a rare treasure. Absolutely love my collection!" },
    { name: "Michael Chen", date: "Aug 22, 2024", text: "The security features of Magic Horn NFTs give me complete peace of mind. Knowing that my investments are safe on the blockchain is reassuring." },
    { name: "Sarah Brown", date: "Oct 02, 2024", text: "Owning a Magic Horn NFT is such an exclusive experience. The limited editions make my collection feel truly special and valuable." },
    { name: "Raj Patel", date: "Jul 18, 2024", text: "The high-quality designs of the Magic Horn NFTs are stunning. Each one is a work of art that stands out in my digital collection." },
    { name: "Emily Turner", date: "Nov 05, 2024", text: "Being part of the Magic Horn community is fantastic. The engagement and exclusive content make the experience even more rewarding." }
  ];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    dots: false,
    items: 1,
    nav: false
  };
}
