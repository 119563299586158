<div class="cart-wrapper">
  <div class="cart-container">
    <div class="cart-header">
      <p>Cart</p>
    </div>
    <div class="cart-product-list">
      <ng-container *ngIf="(cartItems$ | async)?.length; else emptyCart">
        <div class="cart-item" *ngFor="let item of (cartItems$ | async)">
          <button class="remove-button" mat-icon-button (click)="removeFromCart(item.product.productDetails.productUid)">
            <i class="fas fa-trash"></i>
          </button>
          <div class="item-details">
            <p class="product-name">{{ item.product.productDetails.productName }}</p>
            <p>{{ item.product.productDetails.productDescription }}</p>
            <div class="product-color" *ngIf="item.selectedColor">
              <span>Color: {{ item.selectedColor.name }}</span>
              <div class="color-swatch" 
                   [style.background-color]="item.selectedColor.topColor"
                   [style.border-bottom-color]="item.selectedColor.bottomColor || item.selectedColor.topColor"
                   [style.border-bottom-width]="item.selectedColor.bottomColor ? '50%' : '0'">
              </div>
            </div>
          </div>
          <div class="item-single-pricing">
            {{ getProductPrice(item.product, item.quantity) | number:'1.2-2' }} €
          </div>
          <div class="item-quantity">
            <button mat-icon-button (click)="decrementQuantity(item.product.productDetails.productUid)" 
                    [disabled]="item.quantity <= 1 || item.product.productSpecifics.productType === 'online_course'">
              <i class="fas fa-minus"></i>
            </button>
            <div class="item-number">{{ item.quantity }}</div>
            <button mat-icon-button (click)="incrementQuantity(item.product.productDetails.productUid)" 
                    [disabled]="item.product.productSpecifics.productType === 'online_course'">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="item-line-pricing">
            {{ getProductPrice(item.product, item.quantity) * item.quantity | number:'1.2-2' }} €
          </div>
        </div>
      </ng-container>
      <ng-template #emptyCart>
        <div class="cart-empty">
          <p>Your cart is empty.</p>
          <button class="button-blue" routerLink="/shop">Go to shop</button>
        </div>
      </ng-template>
    </div>
    <div class="total-cost">
      <div class="total-price-without-tax">Total Price (excl. Tax): {{ totalPriceWithoutTax | number:'1.2-2' }} €</div>
      <div class="tax-amount">Tax Amount: {{ taxAmount | number:'1.2-2' }} €</div>
      <div class="total-price">Total Price (incl. Tax): {{ totalPriceIncludingTax | number:'1.2-2' }} €</div>
    </div>

    <div class="button-container">
      <button class="button-blue" (click)="navigateToUserDetails()" [disabled]="!(cartItems$ | async)?.length"><span>Checkout</span></button>
    </div>
  </div>
</div>
