<div id="start" class="full-screen-content">
    <div class="seperator-line-blue"></div>
    <div class="seperator-text-wrapper">

        <div *ngIf="unsubscriptionState === 'notConfirmed' && email">
            <div class="text-intro-header">Are you sure?</div>
            <div class="text-main-header">Are you sure you want to unsubscribe from our newsletter?</div>
            <div class="button-content">
                <button *ngIf="email" (click)="confirmUnsubscription(email)">Yes, unsubscribe me</button>
                <button class="button-primary" (click)="declineUnsubscription()">
                    <span>No, I've changed my mind</span>
                </button>
            </div>
        </div>

        <div *ngIf="unsubscriptionState === 'declined'">
            <div class="text-intro-header">Thank you!</div>
            <div class="text-main-header">We're glad you're staying with us. We'll continue to send you our best
                content!</div>
        </div>

        <div *ngIf="unsubscriptionState === 'unsubscribed'">
            <div class="text-intro-header">We're sad to see you go</div>
            <div class="text-main-header">You've been unsubscribed. Hope to see you back soon!</div>
        </div>

        <div *ngIf="unsubscriptionState === 'error'">
            <div class="text-intro-header">Uh-oh, there was a hiccup</div>
            <div class="text-main-header">Something went wrong, but we're on it. Please try again later</div>
        </div>
        <ng-template [appHideOnScreen]="['XSmall', 'Small']">
            <div class="submenu-container">

            </div>
        </ng-template>
    </div>
</div>
