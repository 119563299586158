import { Component, AfterViewInit, ViewChild, ElementRef, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconButton } from '@angular/material/button';
import * as QRCode from 'qrcode';
import { Router, RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackCarouselComponent } from '../../../components/feedback-carousel/feedback-carousel.component';
import { HttpClient } from '@angular/common/http';
import { AlchemyService } from '../../../services/blockchain/alchemy.service';
import { NftSupplyService } from '../../../services/blockchain/nft-supply.service';

@Component({
  selector: 'app-rainbow-portal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconButton,
    MatProgressSpinnerModule,
    RouterModule,
    CarouselModule,
    TranslateModule,
    FeedbackCarouselComponent
  ],
  templateUrl: './rainbow-portal.component.html',
  styleUrls: ['./rainbow-portal.component.scss']
})
export class NftRainbowPortalComponent implements AfterViewInit, OnInit {
  @ViewChild('qrCanvas', { static: false }) qrCanvas!: ElementRef;
  loading = false;
  totalSupply: number = 0;
  nfts: any[] = [];
  lastFetchTime: number = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private router: Router,
    private alchemyService: AlchemyService,
    private nftSupplyService: NftSupplyService,
  ) {}

  ngOnInit(): void {
    this.getNftSupplyData();
    this.loadNftData();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.generateQRCode();
    }
  }
  fetchNftSupply() {
    const now = Date.now();
    
    if (now - this.lastFetchTime >= 60000) {  // 60000 ms = 1 minute
      this.getNftSupplyData();
      this.lastFetchTime = now;
    } else {
      console.log('You can only fetch NFT supply once per minute.');
    }
  }

  async getNftSupplyData() {
    const supplyData = await this.nftSupplyService.getNftCompleteAvailableSupplyData();
    
    if (supplyData) {
      this.totalSupply = supplyData.number;
      console.log('Fetched NFT Supply Data:', supplyData);
    } else {
      console.log('Failed to fetch NFT Supply Data.');
    }
  }

  fetchSentTransactions() {
    const fromAddress = '0xYourContractEthereumAddressHere'; // Replace with actual address
    this.alchemyService.getSentTransactions(fromAddress);
  }

  loadNftData() {
    this.http.get<any[]>('assets/nfts-test.json').subscribe({
      next: data => {
        this.nfts = data;
      },
      error: error => {
        console.error('Error loading NFT data', error);
      }
    });
  }

  generateQRCode(): void {
    const canvas = this.qrCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Could not get canvas context.');
      return;
    }

    const qrData = 'https://opensea.io/collection/courtyard-nft';
    QRCode.toCanvas(canvas, qrData, {
      errorCorrectionLevel: 'high',
      margin: 2,
      scale: 4,
      width: 150,
      color: {
        dark: '#031D2D',
        light: '#FFFFF5'
      }
    }, (error: any) => {
      if (error) {
        console.error('Error generating QR code:', error);
      }
    });
  }

  openShopWithLunaFilter() {
    this.router.navigate(['/shop'], {
      queryParams: {
        productType: 'NFT',
        productCollection: 'Rainbow Portal'
      }
    });
  }
}
