// services/shop/stripe.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private apiUrl = environment.functionsUrl;
  private paymentEndpoint = environment.createStripeShopPayment;

  constructor(private http: HttpClient) {}

  createPaymentIntent(
    amount: number, 
    email: string, 
    userDetails: any, 
    userAddress: any, 
    products: { productUid: string, purchasedAmount: number }[], 
    currency: string
  ): Observable<{ clientSecret: string, stripePurchaseId: string, recoveryUID: string }> {
    const payload = {
      amount,
      email,
      userDetails,
      userAddress,
      products,
      currency
    };

    console.log('Creating payment intent with payload:', payload);

    return this.http.post<{ clientSecret: string, stripePurchaseId: string, recoveryUID: string }>(`${this.apiUrl}${this.paymentEndpoint}`, payload);
  }
}
