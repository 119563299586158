import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-checkout-complete',
  standalone: true,
  imports: [
    CommonModule,

    MatButtonModule,

  ],
  templateUrl: './checkout-complete.component.html',
  styleUrls: ['./checkout-complete.component.scss']
})
export class CheckoutCompleteComponent {
  message = "Purchase Successful! You will receive an email with further instructions.";

  navigateToShop() {
    window.location.href = '/shop';
  }
}
