import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsletterSubscriptionService } from '../../../services/user/newsletter-subscription.service';

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: ['./unsubscribe-email.component.scss']
})
export class UnsubscribeEmailComponent implements OnInit {
  email: string | null = null;
  unsubscriptionState: 'notConfirmed' | 'unsubscribed' | 'error' | 'declined' = 'notConfirmed';

  constructor(
    private route: ActivatedRoute,
    private newsletterService: NewsletterSubscriptionService
  ) { }

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParamMap.get('email');
  }

  async confirmUnsubscription(email: string | null): Promise<void> {
    if (email === null) {
      this.unsubscriptionState = 'error';
      return;
    }

    try {
      await this.newsletterService.markAsUnsubscribed(email);
      this.unsubscriptionState = 'unsubscribed';
    } catch (error) {
      console.error('Error unsubscribing:', error);
      this.unsubscriptionState = 'error';
    }
  }

  declineUnsubscription() {
    this.unsubscriptionState = 'declined';
  }
}
