<div id="checkout">
  <div class="checkout-header">Checkout</div>
  <div class="checkout-wrapper">
    <div class="checkout-container">
      <div class="checkout-user-details">
        Your checkout with <strong>{{ email }}</strong>
      </div>
      
      <div class="checkout-product-list">
        <ng-container *ngIf="(cartItems$ | async)?.length; else emptyCheckout">
          <div class="checkout-item" *ngFor="let item of (cartItems$ | async)">
            <div class="item-details">
              <p class="product-name">{{ item.product.productDetails.productName }}</p>
              <div class="product-color" *ngIf="item.selectedColor">
                <span>Color: {{ item.selectedColor.name }}</span>
                <div class="color-swatch" [style.background-color]="item.selectedColor.topColor"
                  [style.border-bottom-color]="item.selectedColor.bottomColor || item.selectedColor.topColor"
                  [style.border-bottom-width]="item.selectedColor.bottomColor ? '50%' : '0'">
                </div>
              </div>
            </div>
            <div class="item-price-line">
              <div class="item-single-pricing">
                {{ getProductPrice(item.product, item.quantity) | number:'1.2-2' }} €
              </div>
              <div class="item-quantity">x {{ item.quantity }}</div>
              <div class="item-total-pricing">
                {{ getTotalItemPrice(item) | number:'1.2-2' }} €
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #emptyCheckout>
          <div class="checkout-empty">
            <p>Your cart is empty.</p>
            <button class="button-blue" routerLink="/shop">Go to shop</button>
          </div>
        </ng-template>
      </div>
      <div class="total-cost">
        <div class="total-price-without-tax">Total Price (excl. Tax): {{ totalPriceWithoutTax | number:'1.2-2' }} €</div>
        <div class="tax-amount">Tax Amount: {{ taxAmount | number:'1.2-2' }} €</div>
        <div class="total-price">Total Price (incl. Tax): {{ totalPriceIncludingTax | number:'1.2-2' }} €</div>
      </div>

      <div class="disclaimer">
        <ul>
          <li><i class="fas fa-undo-alt"></i> Be aware that custom-made products cannot be returned!</li>
          <li><i class="fas fa-balance-scale"></i> Tax amount will be calculated based on shipping address</li>
          <li><i class="fas fa-exchange-alt"></i> We offer a full refund if only one of the teaching videos has been watched. Fill out the refund request form.</li>
          <li><i class="fas fa-shield-alt"></i> Trusted and secure payments powered by Stripe</li>
          <li><i class="fas fa-check-circle"></i> Satisfaction guaranteed with our online courses</li>
        </ul>
      </div>
    </div>
    <div class="payment-wrapper">
      <div *ngIf="clientSecret; else loading">
        <div class="payment-container">
          <div id="payment-element"></div>
        </div>
        <div class="terms-checkbox" [ngClass]="{'disabled-state': !isStripeFieldsFilled}">
          <mat-checkbox [(ngModel)]="termsAccepted" [disabled]="!isStripeFieldsFilled">
            I accept the terms and conditions
          </mat-checkbox>
        </div>
        
        
        <div class="button-container">
          <button class="button-red" (click)="onConfirmPayment()"
            [disabled]="!(cartItems$ | async)?.length || !termsAccepted">
            <span>Confirm Payment of {{ totalPriceIncludingTax | number:'1.2-2' }} €</span>
          </button>
        </div>
      </div>
      <ng-template #loading>
        <div class="loading-message">
          <p>Initiating payment fields...</p>
          <div class="spinner"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
