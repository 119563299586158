import { Component, AfterViewInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import * as QRCode from 'qrcode';
import { AuthService } from '../../../services/user/user-auth.service';
import { Observable } from 'rxjs';
import { RouterModule, Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { LanguageService } from '../../../services/admin/language.service';
import { NewsletterSubscriptionService } from '../../../services/user/newsletter-subscription.service';
import { TranslateModule } from '@ngx-translate/core';

type SubscriptionStatus = 'success' | 'failure' | 'none' | 'blocked';

@Component({
  selector: 'app-sign-me-up-scotty',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterModule,
    CarouselModule,
    TranslateModule
  ],
  templateUrl: './sign-me-up-scotty.component.html',
  styleUrls: ['./sign-me-up-scotty.component.scss']
})
export class SignMeUpScottyComponent implements AfterViewInit {
  @ViewChild('reviewStarsContainer') reviewStarsContainer?: ElementRef;
  @ViewChild('qrCanvas', { static: false }) qrCanvas!: ElementRef;
  isYearly: boolean = true;
  isLoggedIn$: Observable<boolean>;
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  registrationForm: FormGroup;
  subscriptionStatus: SubscriptionStatus = 'none';
  hidePassword = true;
  hideRepeatPassword = true;
  loading = false;
  uploadedLogo: string | ArrayBuffer | null = null;

  passwordCriteria = {
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasMinLength: false,
  };

  reviews = [
    { name: "coming soon", date: "in the future", text: "For sure, our reviews will be awesome; at least, this is what our independent test users have said so far!" },
    { name: "Alice Johnson", date: "Sep 15, 2024", text: "Seamless check-in and check-out process! I was able to handle everything directly from the app with no hassle. Definitely a five-star experience!" },
    { name: "Michael Chen", date: "Aug 22, 2024", text: "Top-notch security features! I felt really secure with the in-app verification steps and the way they handle personal data. Highly recommend for peace of mind." },
    { name: "Sarah Brown", date: "Oct 02, 2024", text: "The app made managing my booking details so easy! I could update my reservation on-the-go and even extend my stay in just a few taps. A perfect tool for frequent travelers." },
    { name: "Raj Patel", date: "Jul 18, 2024", text: "I’m impressed by the intuitive design of the app which made navigating through options a breeze. Checking room availability, prices, and making payments was straightforward and quick. This app makes hostel stays worry-free." },
    { name: "Emily Turner", date: "Nov 05, 2024", text: "Fantastic features for managing personal information securely. The way the app handles data privacy is top-notch and gives me confidence in using it for all my hostel stays." }
  ];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    dots: false,
    items: 1,
    nav: false
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private languageService: LanguageService,
    private newsletterService: NewsletterSubscriptionService
  ) {
    this.isLoggedIn$ = this.authService.isAuthenticated();
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['']
    });
  }

  ngOnInit(): void {
    this.languageService.loadTranslations(true).subscribe({
      error: error => console.error('Error loading translations:', error)
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.generateQRCode();
    }
  }

  subscribeForMoreInfo(): void {
    if (this.registrationForm.valid) {
      this.loading = true;
      const email = this.registrationForm.value.email;
      
      if (!email) {
        console.error('Email is empty. Cannot subscribe.');
        this.subscriptionStatus = 'failure';
        this.loading = false;
        return;
      }
      
      this.newsletterService.subscribeToNewsletter({ email, newsletterTypes: { userLead: true } })
        .then(() => {
          this.subscriptionStatus = 'success';
        })
        .catch((error: any) => {
          console.error('Error subscribing to the newsletter:', error);
          if (error.message === 'Email Blocked') {
            this.subscriptionStatus = 'blocked';
          } else {
            this.subscriptionStatus = 'failure';
          }
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      console.error('Form is not valid.');
    }
  }

  register() {
    const email = this.registrationForm.get('email')?.value;
    if (email) {
      localStorage.setItem('registerEmail', email);
      window.location.href = `https://my.mikes.menu/register?email=${encodeURIComponent(email)}`;
    }
  }

  resetSubscriptionStatus() {
    this.subscriptionStatus = 'none';
  }

  showFailureMessage() {
    this.subscriptionStatus = 'failure';
    setTimeout(() => {
      this.subscriptionStatus = 'none';
    }, 5000);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedLogo = e.target?.result || null;
        this.generateQRCode();
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  generateQRCode(): void {
    const canvas = this.qrCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Could not get canvas context.');
      return;
    }

    const qrData = 'https://mikes-menu/tap/the-cozy-corner-pub/hamburg-city-center/main-menu';
    QRCode.toCanvas(canvas, qrData, {
      errorCorrectionLevel: 'high',
      margin: 2,
      scale: 4,
      width: 150,
      color: {
        dark: '#031D2D',
        light: '#FFFFF5'
      }
    }, (error: any) => {
      if (error) {
        console.error('Error generating QR code:', error);
      } else {
        this.drawLogo(canvas, ctx);
      }
    });
  }

  drawLogo(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    const logo = new Image();
    logo.crossOrigin = 'anonymous';
    logo.src = (this.uploadedLogo as string) || 'assets/logo/mikes_menu_icon_square_wine.png';

    logo.onload = () => {
      const logoMaxSize = Math.min(canvas.width, canvas.height) * 0.4;
      let logoWidth = logo.width;
      let logoHeight = logo.height;
      if (logoWidth > logoMaxSize || logoHeight > logoMaxSize) {
        const scaleFactor = logoWidth > logoHeight ? logoMaxSize / logoWidth : logoMaxSize / logoHeight;
        logoWidth *= scaleFactor;
        logoHeight *= scaleFactor;
      }

      const logoPosition = {
        x: (canvas.width - logoWidth) / 2,
        y: (canvas.height - logoHeight) / 2
      };

      ctx.clearRect(logoPosition.x, logoPosition.y, logoWidth, logoHeight);
      ctx.drawImage(logo, logoPosition.x, logoPosition.y, logoWidth, logoHeight);
    };

    logo.onerror = (error) => {
      console.error('Error loading logo image:', error);
    };
  }
}
