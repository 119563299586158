import { Product } from '../../../store/models/product.model';

const NFT_BABY_LUNA_COMMON: Product = {
  productDetails: {
    productUid: 'PqWtV2LzXe5mKaBnRf8o',
    productNumber: 'NFT-001',
    productName: 'NFT Baby Luna Common',
    productDescription: 'Entry-level Baby Luna NFT with essential features, perfect for new collectors.',
    productFeatures: `
    Unique digital art crafted to represent the common essence of the Baby Luna collection. This NFT offers blockchain-verified ownership, ensuring its authenticity and secure provenance on the Ethereum network. As a common rarity, it is easily tradeable on secondary markets, making it a perfect starting point for new collectors and those looking to explore the world of NFTs. The Baby Luna Common NFT combines accessibility with artistic value, offering collectors a chance to own a piece of the collection at an entry-level price while still enjoying the benefits of digital ownership and decentralized trading.
  `,
    productInformation: `
    This NFT represents the common rarity level within the Baby Luna collection, making it an accessible entry point for collectors new to the world of digital assets. With its charming yet simple design, this Baby Luna NFT captures the essence of the collection while offering a more abundant supply compared to higher rarities. Ideal for those who wish to start their journey in NFT collecting, the common Baby Luna is perfect for adding a touch of lunar magic to your digital portfolio without the higher cost associated with rarer items. As a common rarity, this NFT maintains its value in the context of the entire collection, offering potential growth as the collection's popularity increases.
  `,
    productStart: new Date(),
    productPublished: false,
  },
  productSpecifics: {
    productType: 'nft',
    fileName: 'nft-baby-luna-common',
    productCollection:'Luna Baby',
    firestoreCollection: 'NFT_LunaBaby',
    affiliateUrl: '',
    smartContractDetails: `
  This Common Baby Luna NFT is governed by a secure and robust ERC-721 smart contract. The contract includes basic features that ensure safe ownership and transfer of NFTs on the Ethereum blockchain. As part of the Common rarity, this NFT is accessible to a broad audience with a mint fee of 0.05 ETH. The contract ensures that each token is uniquely identifiable, stored with its corresponding metadata, and can be easily traded on secondary markets. Common NFTs are more abundant, making them perfect for collectors who are just beginning their journey into the world of NFTs.
`,

  },
  cost: {
    priceAmountSingleItem: 0.05,
    priceCurrency: 'ETH',
    tax: 'none',
    tieredPricing: [],
  },
  images: [
    { imageURL: 'babyunicorn.webp', imageName: 'Common Baby Luna NFT Image' },
  ],
  colors: [],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered instantly on purchase' },
    { icon: 'fas fa-truck', info: 'Blockchain verified' },
    { icon: 'fas fa-undo-alt', info: 'No refunds' },
  ],
};

export default NFT_BABY_LUNA_COMMON;
