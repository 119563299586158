import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, take } from 'rxjs';
import { AppState } from '../../../store/root';
import { selectCartItems, selectCartTotalPrice, selectCartTotalVAT } from '../../../store/selectors/cart.selectors';
import { CartItem } from '../../../store/models/cart-item.model';
import { removeFromCart, incrementQuantity, decrementQuantity, initializeCart } from '../../../store/actions/cart.actions';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { Product } from '../../../store/models/product.model';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  cartItems$: Observable<CartItem[]>;
  cartTotalPrice$: Observable<number>;
  cartTotalVAT$: Observable<number>;
  private subscription: Subscription = new Subscription();

  totalPriceWithoutTax: number = 0;
  taxAmount: number = 0;
  totalPriceIncludingTax: number = 0;

  constructor(private store: Store<AppState>, private router: Router) {
    this.cartItems$ = this.store.select(selectCartItems);
    this.cartTotalPrice$ = this.store.select(selectCartTotalPrice);
    this.cartTotalVAT$ = this.store.select(selectCartTotalVAT);
  }

  ngOnInit(): void {
    this.subscription.add(this.cartItems$.subscribe(cartItems => {
      console.log('Current cart items:', cartItems);
    }));

    this.subscription.add(this.cartItems$.subscribe(cartItems => {
      this.totalPriceWithoutTax = this.getTotalPriceWithoutTax(cartItems);
      this.taxAmount = this.getTaxAmount(cartItems);
      this.totalPriceIncludingTax = this.totalPriceWithoutTax + this.taxAmount;
    }));
    this.subscription.add(this.cartTotalPrice$.subscribe());
    this.subscription.add(this.cartTotalVAT$.subscribe());
    this.cartItems$.pipe(take(1)).subscribe(cartItems => {
      this.store.dispatch(initializeCart({ items: cartItems }));
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeFromCart(productId: string): void {
    this.store.dispatch(removeFromCart({ productId }));
  }

  incrementQuantity(productId: string): void {
    this.store.dispatch(incrementQuantity({ productId }));
  }

  decrementQuantity(productId: string): void {
    this.store.dispatch(decrementQuantity({ productId }));
  }

  getProductPrice(product: Product, quantity: number = 1): number {
    if (product.cost.freeOfCharge) {
      return 0;
    }

    if (product.cost.tieredPricing && product.cost.tieredPricing.length > 0) {
      let pricePerItem = 0;
      for (const tier of product.cost.tieredPricing) {
        if (quantity >= tier.tier) {
          pricePerItem = tier.price;
        }
      }
      return pricePerItem;
    }

    if (product.cost.priceAmountSingleItem) {
      return product.cost.priceAmountSingleItem;
    }

    return 0; // default fallback price
  }

  getTotalPriceWithoutTax(cartItems: CartItem[]): number {
    return cartItems.reduce((total, item) => {
      const itemTotal = this.getProductPrice(item.product, item.quantity) * item.quantity;
      return total + itemTotal;
    }, 0);
  }

  getTaxAmount(cartItems: CartItem[]): number {
    const totalPriceWithoutTax = this.getTotalPriceWithoutTax(cartItems);
    return totalPriceWithoutTax * 0.19; // assuming 19% VAT
  }

  navigateToUserDetails(): void {
    this.router.navigate(['/shop/enter-user-details']);
  }
}
