import { Product } from '../../../store/models/product.model';

const BABY_LUNA_UNICORN_TSHIRT: Product = {
  productDetails: {
    productUid: 'Xa9Gt4kLCz89WdsNpF5u',
    productNumber: 'T002',
    productName: 'Baby Luna Unicorn T-Shirt',
    productDescription: 'Exclusive T-Shirt featuring Baby Luna, the magical Moonborn Unicorn, perfect for those who believe in the magic of the cosmos.',
    productFeatures: '100% organic cotton, Available in multiple sizes (S, M, L, XL, XXL), Durable and vibrant print, Comfortable and soft, Machine washable',
    productInformation: `
      Celebrate the magical journey of Baby Luna with this exclusive "Baby Luna Unicorn" T-Shirt. Made from premium 100% organic cotton, this T-shirt offers unmatched comfort and style for every believer in the magic of the cosmos. The vibrant and durable print showcases Baby Luna, the first Moonborn Unicorn, spreading joy and hope. Whether you're a fan of the mystical or just looking for a comfortable and stylish addition to your wardrobe, this T-shirt is perfect for you. Available in various sizes, it ensures a great fit for everyone.
    `,
    productStart: new Date(),
    productPublished: false,
  },
  productSpecifics: {
    productType: 'Merchandise',
    fileName: 'baby-luna-unicorn-tshirt',
    affiliateUrl: '',
    productCollection: 'Luna Baby',
  },
  cost: {
    priceAmountSingleItem: 35,
    priceCurrency: 'EUR',
    tax: 'incl',
    tieredPricing: []
  },
  images: [
    { imageURL: 'baby-luna-unicorn-tshirt.webp', imageName: 'Baby Luna Unicorn T-Shirt Image' },
  ],
  colors: [
    { name: 'Midnight Black', topColor: '#000000' },
    { name: 'Moonlight White', topColor: '#FFFFFF' }
  ],
  sizes: [
    { size: 'S' },
    { size: 'M' },
    { size: 'L' },
    { size: 'XL' },
    { size: 'XXL' }
  ],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered in 5 days' },
    { icon: 'fas fa-truck', info: 'Free shipping from 150 Euros' },
    { icon: 'fas fa-undo-alt', info: '30-day return policy' }
  ]
};

export default BABY_LUNA_UNICORN_TSHIRT;
