<div class="landing-page-wrapper">
  <div class="banner-box">
    <div class="banner-text">
      <h1>{{ 'landingPage.banner.title' | translate }}</h1>
    </div>
    <div class="feature-header">
      <h2>{{ 'landingPage.banner.featureHeader' | translate }}</h2>
    </div>
    <div class="sample-menu">
      <div class="sample-button">
        <button class="button-square-white" routerLink="/tap/the-cozy-corner-pub/hamburg-city-center/main-menu">
          {{ 'landingPage.banner.sampleMenuButton' | translate }}
        </button>
        <div class="upload-container">
          <button class="button-square-white" (click)="fileInput.click()">
            {{ 'landingPage.banner.uploadLogoButton' | translate }}
          </button>
          <input type="file" (change)="onFileSelected($event)" accept="image/*" #fileInput style="display: none;" />
        </div>
      </div>
      <canvas class="qr-sample-code" #qrCanvas></canvas>
    </div>
  </div>

  <div class="register-wrapper">
    <div class="register-container">
      <form [formGroup]="registrationForm" (ngSubmit)="register()">
        <h2>{{ 'landingPage.register.header' | translate }}</h2>
        <div class="register-input-wrapper">
          <div class="register-landing-page-input">
            <mat-form-field appearance="fill" class="input-field">
              <mat-label>{{ 'landingPage.register.emailLabel' | translate }}</mat-label>
              <input matInput formControlName="email" autocomplete="email">
            </mat-form-field>

            <div class="register-landing-page-input">
              <mat-form-field appearance="fill" class="input-field">
                <mat-label>{{ 'landingPage.register.phoneLabel' | translate }}</mat-label>
                <input matInput formControlName="phoneNumber" autocomplete="tel">
              </mat-form-field>
            </div>
          </div>
          <div class="button-container">
            <div class="button-register-now">
              <button class="button-blue" type="submit" [disabled]="!registrationForm.valid || loading"
                [ngClass]="{'disabled': !registrationForm.valid || loading}">
                <ng-container *ngIf="loading; else registerText">
                  <mat-spinner diameter="20"></mat-spinner>
                </ng-container>
                <ng-template #registerText><span>{{ 'landingPage.register.registerButton' | translate }}</span></ng-template>
              </button>
            </div>
            <div class="button-more-info">
              <button *ngIf="subscriptionStatus === 'none'" class="button-blue" type="button"
                (click)="subscribeForMoreInfo()" [disabled]="!registrationForm.valid || loading"
                [ngClass]="{'disabled': !registrationForm.valid || loading}">
                <ng-container *ngIf="loading; else subscribeText">
                  <mat-spinner diameter="20"></mat-spinner>
                </ng-container>
                <ng-template #subscribeText><span>{{ 'landingPage.register.contactButton' | translate }}</span></ng-template>
              </button>
              <div *ngIf="subscriptionStatus === 'success'" class="button-message-done">
                {{ 'landingPage.register.subscriptionSuccess' | translate }}
              </div>
              <div *ngIf="subscriptionStatus === 'failure'" class="button-message-alarm">
                <div class="failure-message">
                  {{ 'landingPage.register.subscriptionFailure' | translate }}
                </div>
                <button class="button-primary" (click)="resetSubscriptionStatus()">
                  <span>{{ 'landingPage.register.retryButton' | translate }}</span>
                </button>
              </div>
              <div *ngIf="subscriptionStatus === 'blocked'" class="button-message-alarm">
                <div class="blocked-message">
                  {{ 'landingPage.register.subscriptionBlocked' | translate }}
                </div>
                <button class="button-primary" (click)="resetSubscriptionStatus()">
                  <span>{{ 'landingPage.register.retryButton' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <section class="features-wrapper">
    <div class="features-elements-wrapper">
      <div class="features-container">
        <div class="feature">
          <i class="fas fa-sync-alt"></i>
          <h2>{{ 'landingPage.features.realTimeUpdates.title' | translate }}</h2>
          <p>{{ 'landingPage.features.realTimeUpdates.description' | translate }}</p>
        </div>
        <div class="feature">
          <i class="fas fa-language"></i>
          <h2>{{ 'landingPage.features.multilingualSupport.title' | translate }}</h2>
          <p>{{ 'landingPage.features.multilingualSupport.description' | translate }}</p>
        </div>
        <div class="feature">
          <i class="fas fa-mobile-alt"></i>
          <h2>{{ 'landingPage.features.mobileFriendlyDesign.title' | translate }}</h2>
          <p>{{ 'landingPage.features.mobileFriendlyDesign.description' | translate }}</p>
        </div>
      </div>
      <div class="features-container">
        <div class="feature">
          <i class="fas fa-search"></i>
          <h2>{{ 'landingPage.features.searchFunctionality.title' | translate }}</h2>
          <p>{{ 'landingPage.features.searchFunctionality.description' | translate }}</p>
        </div>
        <div class="feature">
          <i class="fas fa-palette"></i>
          <h2>{{ 'landingPage.features.differentStyles.title' | translate }}</h2>
          <p>{{ 'landingPage.features.differentStyles.description' | translate }}</p>
        </div>
        <div class="feature">
          <i class="fas fa-th-list"></i>
          <h2>{{ 'landingPage.features.dragDropElements.title' | translate }}</h2>
          <p>{{ 'landingPage.features.dragDropElements.description' | translate }}</p>
        </div>
      </div>
    </div>
  </section>

  <div class="feedback-wrapper">
    <div class="feedback-container">
      <div class="feedback-header">
        {{ 'landingPage.feedback.header' | translate }}
      </div>
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let review of reviews">
          <ng-template carouselSlide>
            <div class="feedback-customer">
              <div class="customer-review">
                <p>" {{ review.text }} "</p>
                <div class="review-small">{{ review.name }} - {{ review.date }}</div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>

  <div class="quote-wrapper"></div>
  <div class="know-more-wrapper">
    <div class="know-more-container">
      <h2>{{ 'landingPage.knowMore.header' | translate }}</h2>
      <div class="know-more-button">
        <button class="button-square-white" routerLink="/">{{ 'landingPage.knowMore.mainPageButton' | translate }}</button>
      </div>
    </div>
  </div>
</div>
