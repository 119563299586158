<div class="home-wrapper">
  <div class="start-container">
    <div class="banner-text banner-left">
      <div class="banner-text">
        <h1>The Magic Horn Blockchain</h1>
        <p>Join us on a journey where blockchain magic, fortune, and endless possibilities await. Unveil unique NFTs
          and exclusive coins inspired by the mystical power of the Magic Horn.</p>
      </div>
    </div>
  </div>

  <div class="sub-banner-container">
    <div class="sub-banner-button">

      <div class="actions">
        <button class="button-square-pink" routerLink="/shop">Visit our offers</button>
      </div>
      <div class="actions">
        <button class="button-square-pink" routerLink="/the-story">the story of the magic horn</button>
      </div>
    </div>
  </div>

  <section class="features-wrapper">
    <div class="feature-header">
      <h2>Empower Your Financial Future</h2>
      <p>Explore our exclusive offerings: Magic Horn Coin coming soon and various NFTs with great value. Our first NFT
        is "Baby Luna". Invest in these
        assets to secure your financial future.</p>
    </div>

    <div class="features-container">
      <div class="feature">
        <i class="fas fa-coins"></i>
        <h2>Magic Horn Coin</h2>
        <p>Get ready for our exclusive cryptocurrency, designed to revolutionize the way you trade and invest.</p>
        <p>coming soon</p>
      </div>
      <div class="feature">
        <i class="fas fa-photo-video"></i>
        <h2>Valuable NFTs</h2>
        <p>Own unique and high-value NFTs that offer both aesthetic and financial benefits.</p>
      </div>
      <div class="feature">
        <i class="fas fa-certificate"></i>
        <h2>Certification</h2>
        <p>Receive a certificate upon purchase to validate your investment in our exclusive offerings.</p>
      </div>
      <div class="feature">
        <i class="fas fa-calendar-alt"></i>
        <h2>Flexible Trading</h2>
        <p>Trade and invest in our assets at your convenience, anytime, anywhere. No restrictions through our smart
          contracts.</p>
      </div>
      <div class="feature">
        <i class="fas fa-comments"></i>
        <h2>Community Support</h2>
        <p>Join a community of like-minded investors to share insights and ask questions on Discord.</p>
      </div>
      <div class="feature">
        <i class="fas fa-network-wired"></i>
        <h2>Ethereum and BSC</h2>
        <p>Our NFTs and Magic Horn Coin are powered by the robust Ethereum and Binance Smart Chain networks, ensuring
          fast, secure, and decentralized transactions.</p>
      </div>
    </div>
  </section>

  <section class="steps-container">
    <h2>Clever Steps to victory</h2>
    <div class="steps">
      <div class="step">
        <i class="fas fa-1"></i>
        <h3>Explore Offerings</h3>
        <p>Browse through our exclusive Magic Horn Coin and NFTs. Each NFT is unique, carrying the essence of the
          magical world of Magic Horn.</p>
      </div>
      <div class="step">
        <i class="fas fa-2"></i>
        <h3>Get Your Digital Wallet</h3>
        <p>Set up a digital wallet such as MetaMask. This will allow you to securely store and manage your
          cryptocurrencies and NFTs.</p>
      </div>
      <div class="step">
        <i class="fas fa-3"></i>
        <h3>Buy Some Ether or BSC</h3>
        <p>Purchase Ether or Binance Smart Chain (BSC) tokens, depending on the current offers. These will be used to
          buy Magic Horn NFTs and coins.</p>
      </div>
      <div class="step">
        <i class="fas fa-4"></i>
        <h3>Trade</h3>
        <p>Trade your assets with ease and flexibility. Use our platform to buy, sell, or trade your Magic Horn Coins
          and NFTs, maximizing your potential gains.</p>
      </div>
      <div class="step">
        <i class="fas fa-5"></i>
        <h3>Hold or Earn</h3>
        <p>Watch your investments grow. Hold onto your Magic Horn Coins and NFTs for long-term gains or participate in
          earning programs for additional rewards.</p>
      </div>
      <div class="step">
        <i class="fas fa-6"></i>
        <h3>Show Your NFT</h3>
        <p>Get a digital screen to showcase your Magic Horn NFT. Make your home as unique as you are by displaying your
          digital art collection.</p>
      </div>
    </div>
  </section>


  <section class="pricing-container">

    <h2>Some of our exclusive offerings</h2>

    <div class="pricing-tiers">
      <!--
      <div class="tier">
        <div class="tier-content">
          <h3>Magic Horn Coin</h3>
          <ul>
            <li>Exclusive cryptocurrency</li>
            <li>High potential value</li>
            <li>Expert insights</li>
            <li>Community support</li>
          </ul>
        </div>
        <a *ngIf="!(isLoggedIn$ | async)" routerLink="/shop" class="button-blue"><span>add to cart</span></a>
        <a *ngIf="isLoggedIn$ | async" href="https://magic-horn.io" class="button-blue"><span>Go to Coin</span></a>
      </div>
      -->
      <div class="tier">
        <div class="tier-content">
          <h3>Selene NFTs, Volume 1</h3>
          <ul>
            <li>Capturing the start of Selene's journey</li>
            <li>Unique digital assets in high resolution</li>
            <li>High aesthetic and financial value</li>
            <li>Secure IPFS network storage of images</li>
            <li>Exclusive and limited edition NFTs</li>
          </ul>
        </div>
        <a (click)="navigateToProduct()" class="button-red"><span>show more details</span></a>
      </div>
      
      
    </div>
    <div class="shop-button">
      <a routerLink="/shop" class="button-blue"><span>See all our offers</span></a>
    </div>
  </section>

  <app-feedback-carousel></app-feedback-carousel>
</div>