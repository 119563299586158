import { Injectable } from '@angular/core';
import { collection, query, where, getDocs, limit } from '@angular/fire/firestore';
import { Nft } from '../../store/models/nft.model';
import { doc, Firestore, getDoc, getFirestore } from 'firebase/firestore';
import { SnackbarService } from '../../components/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class NftSupplyService {
  private firestore: Firestore;
  constructor(

    private snackbarService: SnackbarService
  ) {
    this.firestore = getFirestore();
  }

  async getNftSupplyData(firestoreCollection: string, cidAssignment: string, cidGroup?: string, cidNumber?: string): Promise<Nft | null> {
    let nftQuery;
  
    try {
      switch (cidAssignment) {
        case 'random':
          nftQuery = query(
            collection(this.firestore, firestoreCollection),
            where('minted', '==', false)
          );
          break;
  
        case 'group':
          if (!cidGroup) throw new Error('No group specified for CID group assignment.');
          nftQuery = query(
            collection(this.firestore, firestoreCollection),
            where('group', '==', cidGroup),
            where('minted', '==', false)
          );
          break;
  
        case 'specific':
          if (!cidNumber) throw new Error('No specific CID number provided.');
          nftQuery = query(
            collection(this.firestore, firestoreCollection),
            where('cid', '==', cidNumber),
            where('minted', '==', false)
          );
          break;
  
        default:
          throw new Error('Invalid CID assignment type.');
      }
  
      const querySnapshot = await getDocs(nftQuery);
  
      if (querySnapshot.empty) {
        throw new Error(`No available NFT found for ${cidAssignment} assignment.`);
      }
  
      // Randomly select one document from the available unminted NFTs
      const randomIndex = Math.floor(Math.random() * querySnapshot.docs.length);
      const selectedNftData = querySnapshot.docs[randomIndex].data() as Nft;
  
      this.snackbarService.showCustomSnackbar('NFT data retrieved successfully.');
      return selectedNftData;
  
    } catch (error) {
      console.error('Error retrieving NFT data:', error);
      this.snackbarService.showCustomSnackbar('NFT data not retrieved');
      return null;
    }
  }
  
  

  async getNftCompleteAvailableSupplyData() {
    try {
      const supplyDocRef = doc(this.firestore, 'NFT_Supply', 'NFT_RainbowPortal');
      const supplyDocSnapshot = await getDoc(supplyDocRef);

      if (supplyDocSnapshot.exists()) {
        const data = supplyDocSnapshot.data();
        this.snackbarService.showCustomSnackbar('Supply data retrieved successfully.');
        return {
          number: data['number'],
          lastUpdated: data['lastUpdated']
        };
      } else {
        throw new Error('No such document!');
      }
    } catch (error) {
      console.error();
      this.snackbarService.showCustomSnackbar('Error retrieving supply data.');
      return null;
    }
  }
}
