import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, firstValueFrom } from 'rxjs';
import { AppState } from '../../../store/root';
import { selectCartItems, selectCartTotalPrice, selectCartTotalVAT } from '../../../store/selectors/cart.selectors';
import { CartItem } from '../../../store/models/cart-item.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Product } from '../../../store/models/product.model';
import { checkoutSuccess, checkoutFailure, clearCart } from '../../../store/actions/cart.actions';
import { environment } from '../../../../environments/environment';
import { SnackbarService } from '../../../components/snack-bar/snack-bar.service';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { StripeService } from '../../../services/shop/stripe.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthService } from '../../../services/user/user-auth.service';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatLabel,
    ReactiveFormsModule,
  ],
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  cartItems$: Observable<CartItem[]>;
  cartTotalPrice$: Observable<number>;
  cartTotalVAT$: Observable<number>;
  private subscription: Subscription = new Subscription();
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  clientSecret: string | null = null;
  termsAccepted: boolean = false;
  email: string | null = null;
  totalPriceWithoutTax: number = 0;
  taxAmount: number = 0;
  totalPriceIncludingTax: number = 0;
  isStripeFieldsFilled: boolean = false; 
  stripePurchaseId: string | null = null;
  recoveryUID: string | null = null;

  appearance = {
    theme: 'flat' as const,
    labels: 'floating' as const,
    variables: {
      colorPrimary: '#031D2D',
      colorBackground: '#FFFFF5',
      colorText: '#031D2D',
      colorDanger: '#8C2703',
      fontFamily: 'Lora, sans-serif',
      borderRadius: '5px',
    },
  };

  userDetails: any;

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private stripeService: StripeService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router
  ) {
    this.cartItems$ = this.store.select(selectCartItems);
    this.cartTotalPrice$ = this.store.select(selectCartTotalPrice);
    this.cartTotalVAT$ = this.store.select(selectCartTotalVAT);
  }

  async ngOnInit(): Promise<void> {
    this.userDetails = history.state.userDetails;
    this.email = this.userDetails?.email || null;
    this.stripe = await loadStripe(environment.stripeTestPublishableKey);

    this.subscription.add(this.cartItems$.subscribe(cartItems => {
      this.totalPriceWithoutTax = this.getTotalPriceWithoutTax(cartItems);
      this.taxAmount = this.getTaxAmount(cartItems);
      this.totalPriceIncludingTax = this.totalPriceWithoutTax + this.taxAmount;
    }));

    this.fetchClientSecretAndInitializeElements();

    this.subscription.add(
      this.actions$.pipe(ofType(checkoutSuccess)).subscribe(action => {
        this.clientSecret = action.clientSecret;
        this.initializeStripeElements();
        this.snackbarService.showCustomSnackbar('Payment Successful');
      })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(checkoutFailure)).subscribe(() => {
        this.snackbarService.showCustomSnackbar('Payment Error, Try again');
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async fetchClientSecretAndInitializeElements(): Promise<void> {
    const totalAmount = await firstValueFrom(this.cartTotalPrice$);
    const email = this.userDetails.email;
    const userAddress = this.userDetails.userAddress;
    const userDetails = this.userDetails.userDetails;
    const cartItems = await firstValueFrom(this.cartItems$);
    const products = cartItems.map(item => ({ 
      productUid: item.product.productDetails.productUid, 
      productName: item.product.productDetails.productName,
      productType: item.product.productSpecifics.productType,

      purchasedAmount: item.quantity 
    }));
  
    if (totalAmount === undefined || !email) {
      console.error('Total amount or email is undefined. Stripe failed to init');
      return;
    }
  
    try {
      const response = await firstValueFrom(this.stripeService.createPaymentIntent(totalAmount * 100, email, userDetails, userAddress, products, 'EUR'));
      this.clientSecret = response.clientSecret;
      this.stripePurchaseId = response.stripePurchaseId;
      this.recoveryUID = response.recoveryUID;
      this.initializeStripeElements();
    } catch (error) {
      console.error('Error fetching client secret:', error);
    }
  }
  

  initializeStripeElements(): void {
    if (!this.stripe || !this.clientSecret) {
      console.error('Stripe.js has not been initialized');
      return;
    }

    this.elements = this.stripe.elements({
      clientSecret: this.clientSecret,
      appearance: this.appearance,
    });

    setTimeout(() => {
      const paymentElement = this.elements!.create('payment');
      paymentElement.mount('#payment-element');
      paymentElement.on('change', (event) => {
        this.isStripeFieldsFilled = event.complete;
      });
    }, 0);
  }

  async onConfirmPayment(): Promise<void> {
    if (!this.stripe || !this.elements) {
      console.error('Stripe.js has not been initialized');
      return;
    }
  
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: `${window.location.origin}/shop/checkout-complete`,
      },
    });
  
    if (error) {
      this.snackbarService.showCustomSnackbar('Payment Error, Try again');
    } else {
      this.store.dispatch(clearCart());
    }
  }

  getProductPrice(product: Product, quantity: number = 1): number {
    if (product.cost.freeOfCharge) {
      return 0;
    }

    if (product.cost.tieredPricing && product.cost.tieredPricing.length > 0) {
      let pricePerItem = 0;
      for (const tier of product.cost.tieredPricing) {
        if (quantity >= tier.tier) {
          pricePerItem = tier.price;
        }
      }
      return pricePerItem;
    }

    if (product.cost.priceAmountSingleItem) {
      return product.cost.priceAmountSingleItem;
    }

    return 0;
  }

  getTotalItemPrice(item: CartItem): number {
    return this.getProductPrice(item.product, item.quantity) * item.quantity;
  }

  getTotalPriceWithoutTax(cartItems: CartItem[]): number {
    return cartItems.reduce((total, item) => total + this.getTotalItemPrice(item), 0);
  }

  getTaxAmount(cartItems: CartItem[]): number {
    const totalPriceWithoutTax = this.getTotalPriceWithoutTax(cartItems);
    return totalPriceWithoutTax * 0.19;
  }

  getTotalPriceIncludingTax(cartItems: CartItem[]): number {
    const totalPriceWithoutTax = this.getTotalPriceWithoutTax(cartItems);
    const taxAmount = this.getTaxAmount(cartItems);
    return totalPriceWithoutTax + taxAmount;
  }

  logout(event: Event): void {
    event.preventDefault();
    this.authService.logoutUser().then(() => { });
  }
}
