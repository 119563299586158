import { Action, createReducer, on } from '@ngrx/store';
import * as LanguageActions from '../actions/language.actions';

export const initialState: string = 
  (typeof window !== 'undefined' && localStorage.getItem('appLang')) || 
  (typeof window !== 'undefined' && navigator.language.split('-')[0]) || 
  'en';
  
const _languageReducer = createReducer(
  initialState,
  on(LanguageActions.setLanguage, (_, { language }) => language)
);

export function languageReducer(state: string | undefined, action: Action) {
  return _languageReducer(state, action);
}
