// src/app/components/cookie-consent/cookie-consent.component.ts
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CookieConsentService } from '../../services/other/cookie-consent.service';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
  hasConsented = false;

  private cookieConsentService = inject(CookieConsentService);

  ngOnInit(): void {
    this.hasConsented = this.cookieConsentService.hasConsented();
    if (this.hasConsented) {
      this.cookieConsentService.setConsent(true);
    }
  }

  acceptCookies(): void {
    this.cookieConsentService.setConsent(true);
    this.hasConsented = true;
  }

  declineCookies(): void {
    this.cookieConsentService.setConsent(false);
    this.hasConsented = true;
  }
}
