import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import lottie, { AnimationItem } from 'lottie-web';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatSlideToggleModule,
    RouterModule,
  ],
  templateUrl: './the-story.component.html',
  styleUrls: ['./the-story.component.scss']
})
export class TheStoryComponent  implements OnInit, OnDestroy  {
  @ViewChild('lottieMoonContainer') lottieMoonContainer?: ElementRef;
  @ViewChild('lottieSpiritContainer') lottieSpiritContainer?: ElementRef;
  @ViewChild('lottieFlowerOpenContainer') lottieFlowerOpenContainer?: ElementRef;
  @ViewChild('lottieMagicRotationContainer') lottieMagicRotationContainer?: ElementRef;
  @ViewChild('lottieMagicChangeContainer') lottieMagicChangeContainer?: ElementRef;
  @ViewChild('lottieRotatingContainer') lottieRotatingContainer?: ElementRef;
  @ViewChild('lottieWritingContainer') lottieWritingContainer?: ElementRef;
  @ViewChild('lottieHeartContainer') lottieHeartContainer?: ElementRef;
  @ViewChild('lottieAidContainer') lottieAidContainer?: ElementRef;
  @ViewChild('lottieUnicornContainer') lottieUnicornContainer?: ElementRef;

  @ViewChild('homeWrapper') homeWrapper?: ElementRef;
  private animationItem: AnimationItem | null = null;

  constructor(
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.initLottie(this.lottieMoonContainer, 'assets/lottie/moon.json');
    this.initLottie(this.lottieSpiritContainer, 'assets/lottie/spirit.json');
    this.initLottie(this.lottieFlowerOpenContainer, 'assets/lottie/floweropen.json');
    this.initLottie(this.lottieMagicRotationContainer, 'assets/lottie/magic-rotation.json');
    this.initLottie(this.lottieMagicChangeContainer, 'assets/lottie/magic-change.json');
    this.initLottie(this.lottieRotatingContainer, 'assets/lottie/rotating.json');
    this.initLottie(this.lottieWritingContainer, 'assets/lottie/writing.json');
    this.initLottie(this.lottieHeartContainer, 'assets/lottie/heart.json');
    this.initLottie(this.lottieAidContainer, 'assets/lottie/aid.json');
    this.initLottie(this.lottieUnicornContainer, 'assets/lottie/unicorn.json');
  }

  private initLottie(container: ElementRef | undefined, path: string): void {
    if (!container) return;
    this.animationItem = lottie.loadAnimation({
      container: container.nativeElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path
    });
  }

  ngOnDestroy(): void {
    this.animationItem?.destroy();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const panels = this.homeWrapper?.nativeElement.querySelectorAll('.panel');

    if (panels) {
      panels.forEach((panel: HTMLElement) => {
        const panelTop = panel.offsetTop;
        const panelHeight = panel.offsetHeight;

        if (scrollPosition >= panelTop - window.innerHeight / 3 && scrollPosition < panelTop + panelHeight - window.innerHeight / 3) {
          const color = panel.getAttribute('data-color');
          this.clearWrapperClasses();
          if (color) {
            this.renderer.addClass(this.homeWrapper?.nativeElement, `color-${color}`);
          }
        }
      });
    }
  }

  private clearWrapperClasses(): void {
    const wrapperClassList = this.homeWrapper?.nativeElement.classList;
    if (wrapperClassList) {
      wrapperClassList.forEach((cls: string) => {
        if (cls.startsWith('color-')) {
          this.renderer.removeClass(this.homeWrapper?.nativeElement, cls);
        }
      });
    }
  }
}
