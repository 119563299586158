<mat-toolbar *ngIf="!isMobile" class="topbar-content">
  <div class="logo-container">
    <div class="logo-center" routerLink="/home">
      <img [src]="'/assets/logo/magic-horn-pink-long.png'" alt="Logo" class="logo">
    </div>
  </div>
  <div class="menu-container">
    <div>
      <button *ngIf="(languages$ | async)" mat-icon-button class="language-button" [matMenuTriggerFor]="langMenu">
        <i class="fas fa-globe"></i>
      </button>
      <mat-menu class="language-dropdown" #langMenu="matMenu">
        <ng-container *ngIf="languages$ | async as languages">
          <button *ngFor="let lang of (languages | keyvalue)" mat-menu-item
            [class.active-language]="currentLanguage === lang.key" (click)="switchLanguageIcon(lang.key)">
            {{ lang.value.nativeName }} ({{ lang.value.name }})
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <button class="button-menu" routerLink="/home">{{ 'toolbar.home' | translate }}</button>
    <button class="button-menu" routerLink="/nft/rainbow-portal">nft:RAINBOW-PORTAL</button>
    <button class="button-menu" routerLink="/the-story">the story</button>



    <div class="login-buttons">
      <!--
          <button class="button-menu" *ngIf="isLoggedIn$ | async" (click)="goToUserDashboard()">
      user dashboard
    </button>
      <button *ngIf="!(isLoggedIn$ | async)" class="button-menu" (click)="goToUserLogin()">{{ 'toolbar.login' |
        translate }}</button>
      <button mat-icon-button *ngIf="isLoggedIn$ | async" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i>
      </button> -->
      <button class="button-menu button-register" (click)="goToShop()">shop</button>
      <button *ngIf="(cartItemCount$ | async) ?? 0 > 0" class="cart-button" mat-icon-button routerLink="/shop/cart">
        <i class="fas fa-shopping-cart"></i>
        <span class="cart-count">{{ (cartItemCount$ | async) ?? 0 }}</span>
      </button>

    </div>
  </div>
</mat-toolbar>

<!-- Mobile Toolbar -->
<mat-toolbar *ngIf="isMobile" class="topbar-content-mobile">
  <div class="mobile-container">
    <img [src]="'/assets/logo/magic-horn-pink-square.png'" alt="Logo" class="logo" routerLink="/home">
    <div class="mobile-menu-button">
      <button class="cart-button" mat-icon-button routerLink="/shop/cart">
        <i class="fas fa-shopping-cart"></i>
        <span class="cart-count" *ngIf="cartItemCount$ | async as count">{{ count }}</span>
      </button>
      <button *ngIf="(languages$ | async)" mat-icon-button class="language-button" [matMenuTriggerFor]="langMenu">
        <i class="fas fa-language"></i>
      </button>
      <mat-menu class="language-dropdown" #langMenu="matMenu">
        <ng-container *ngIf="languages$ | async as languages">
          <button *ngFor="let lang of (languages | keyvalue)" mat-menu-item
            [class.active-language]="currentLanguage === lang.key" (click)="switchLanguageIcon(lang.key)">
            {{ lang.value.nativeName }} ({{ lang.value.name }})
          </button>
        </ng-container>
      </mat-menu>
      <button mat-icon-button (click)="toggleMobileMenu()">
        <i class="fas fa-bars"></i>
      </button>
    </div>
  </div>
</mat-toolbar>

<div class="mobile-menu-overlay" *ngIf="isMobileMenuOpen" (click)="closeMobileMenu()"></div>

<div class="mobile-menu" [class.open]="isMobileMenuOpen">
  <button mat-icon-button (click)="toggleMobileMenu()">
    <i class="fas fa-times"></i>
  </button>
  <button class="button-menu" routerLink="/home" (click)="closeMobileMenu()">{{ 'toolbar.home' | translate }}</button>
  <button class="button-menu" routerLink="/nft/marketplace" (click)="closeMobileMenu()">nft marketplace</button>
  <button class="button-menu" routerLink="/nft/baby-luna" (click)="closeMobileMenu()">nft:BABYLUNA</button>
  <button class="button-menu" routerLink="/the-story" (click)="closeMobileMenu()">the story</button>

  <!--
  <button class="button-menu" *ngIf="isLoggedIn$ | async" (click)="goToUserDashboard(); closeMobileMenu()">
    user dashboard
  </button>
  <div class="login-buttons">
    <button *ngIf="!(isLoggedIn$ | async)" class="button-menu" (click)="goToUserLogin(); closeMobileMenu()">{{ 'toolbar.login' | translate }}</button>
    <button class="button-menu button-register" (click)="goToShop(); closeMobileMenu()">shop</button>
    <button mat-icon-button *ngIf="isLoggedIn$ | async" (click)="logout(); closeMobileMenu()">
      <i class="fas fa-sign-out-alt"></i>
    </button>
  </div> -->
</div>

<div>
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  <app-cookie-consent></app-cookie-consent>
  <router-outlet *ngIf="!isLoading"></router-outlet>
  <app-footer></app-footer>
</div>