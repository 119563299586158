import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as LanguageActions from '../store/actions/language.actions';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard {

  constructor(

    private translate: TranslateService,
    private store: Store
  ) {}

  canActivate(): Observable<boolean> {
    const browserLang = this.translate.getBrowserLang() || 'en';
    const supportedLangs = ['en', 'de', 'nl', 'da', 'es', 'fi', 'fr', 'it', 'no', 'pl', 'pt', 'ru', 'sv', 'uk'];
    const currentLanguage = supportedLangs.includes(browserLang) ? browserLang : 'en';
 //   console.log(`Browser language detected: ${browserLang}. Using language: ${currentLanguage}`);

    this.store.dispatch(LanguageActions.setLanguage({ language: currentLanguage }));

    return this.translate.use(currentLanguage).pipe(
      map(() => {
     //   console.log(`Language set to: ${this.translate.currentLang}`);
        return true;
      }),
      catchError(err => {
        console.error(`Error setting language: ${err}`);
        return of(false);
      })
    );
  }
}
