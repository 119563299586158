import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { TheStoryComponent } from './pages/the-story/the-story.component';
import { VerifyEmailComponent } from './pages/other/verify-email/verify-email.component';
import { UnsubscribeEmailComponent } from './pages/other/unsubscribe-email/unsubscribe-email.component';
import { ShopComponent } from './pages/shop/overview/shop.component';
import { ProductDetailsComponent } from './pages/shop/product-details/product-details.component';
import { CartComponent } from './pages/shop/cart/cart.component';
import { CheckoutCompleteComponent } from './pages/shop/checkout-complete/checkout-complete.component';
import { CheckoutComponent } from './pages/shop/checkout-payment/checkout.component';

import { LanguageGuard } from './guard/language.guard';
import { CookiePolicyComponent } from './pages/other/legal/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './pages/other/legal/privacy-policy/privacy-policy.component';
import { TermsLongComponent } from './pages/other/legal/terms-long/terms.component';
import { TermsShortComponent } from './pages/other/legal/terms-short/terms-short.component';
import { RefundReturnPolicyComponent } from './pages/other/legal/payment-refund-policy/payment-refund-policy.component';
import { UserDetailsComponent } from './pages/shop/checkout-user-details/user-details.component';
import { RegistrationCompleteComponent } from './pages/shop/registration-complete/registration-complete.component';
import { BlogListComponent } from './pages/blog/blog-list/blog-list.component';
import { BlogPostComponent } from './pages/blog/blog-post/blog-post.component';
import { SignMeUpScottyComponent } from './pages/landing-page/sign-me-up-scotty/sign-me-up-scotty.component';
import { AdRedirectComponent } from './ad-redirect.component';

import { Web3ModalComponent } from './pages/shop/product-details/web3-modal/web3-modal.component';
import { HomeComponent } from './pages/home/home.component';
import { NftMarketplaceComponent } from './pages/nfts/nft-marketplace/nft-marketplace.component';
import { NftBabyLunaMarketplaceComponent } from './pages/nfts/baby-luna/baby-luna.component';
import { NftRainbowPortalComponent } from './pages/nfts/rainbow-portal/rainbow-portal.component';
import { MintCompletedComponent } from './pages/shop/mint-completed/mint-completed.component';

export const routes: Routes = [
  { 
    path: '', 
    component: HomeComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Home', 
      description: 'Welcome to our homepage' 
    } 
  },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { 
    path: 'verify-email', 
    component: VerifyEmailComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Verify Email', 
      description: 'Please verify your email' 
    } 
  },
  { 
    path: 'shop', 
    component: ShopComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Shop', 
      description: 'Browse our products' 
    } 
  },

  { 
    path: 'shop/mint-completed', 
    component: MintCompletedComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Mint Completed', 
      description: 'you got your desired nft!' 
    } 
  },

  { 
    path: 'test', 
    component: Web3ModalComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Shop', 
      description: 'Browse our products' 
    } 
  },

  { 
    path: 'the-story', 
    component: TheStoryComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Shop', 
      description: 'Browse our products' 
    } 
  },
  { 
    path: 'shop/product-details/:productId', 
    component: ProductDetailsComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Shop', 
      description: 'Browse our products' 
    } 
  },
  { 
    path: 'shop/cart', 
    component: CartComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Cart', 
      description: 'View your cart' 
    } 
  },
  { 
    path: 'shop/enter-user-details', 
    component: UserDetailsComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Checkout', 
      description: 'Complete your purchase' 
    } 
  },
  { 
    path: 'shop/checkout', 
    component: CheckoutComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Checkout', 
      description: 'Complete your purchase' 
    } 
  },
  { 
    path: 'shop/checkout-complete', 
    component: CheckoutCompleteComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Purchase Done', 
      description: 'Thank you for your purchase' 
    } 
  },
  { 
    path: 'shop/registration-complete', 
    component: RegistrationCompleteComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Purchase Done', 
      description: 'Thank you for your purchase' 
    } 
  },

  /*
  {
    path: 'blog',
    component: BlogListComponent,
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Blog Overview', 
      description: 'Thank you for your purchase' 
    } 
  },
  {
    path: 'blog/:slug',
    component: BlogPostComponent,
    canActivate: [LanguageGuard],
    data: { 
      title: 'Blog Detail', 
      description: 'Thank you for your purchase' 
    } 
  },

  { 
    path: 'nft/marketplace', 
    component: NftMarketplaceComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Landing Page', 
      description: 'Welcome to our landing page' 
    } 
  },



*/

  { 
    path: 'unsubscribe-email', 
    component: UnsubscribeEmailComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Unsubscribe Email', 
      description: 'Unsubscribe from our mailing list' 
    } 
  },
  { 
    path: 'lp', 
    component: AdRedirectComponent,
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Ad Redirect', 
      description: 'Redirecting to the appropriate landing page' 
    } 
  },
  { 
    path: 'lp/sign-me-up-scotty', 
    component: SignMeUpScottyComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Landing Page', 
      description: 'Welcome to our landing page' 
    } 
  },

  { 
    path: 'nft/baby-luna', 
    component: NftBabyLunaMarketplaceComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Landing Page', 
      description: 'Welcome to our landing page' 
    } 
  },

  { 
    path: 'nft/rainbow-portal', 
    component: NftRainbowPortalComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Landing Page', 
      description: 'Welcome to our landing page' 
    } 
  },
  { 
    path: 'cookie', 
    component: CookiePolicyComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Disclaimer', 
      description: 'Legal disclaimer and notices' 
    } 
  },
  { 
    path: 'refund', 
    component: RefundReturnPolicyComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Refund Policy', 
      description: 'Our refund and return policy' 
    } 
  },
  { 
    path: 'privacy', 
    component: PrivacyPolicyComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Privacy Policy', 
      description: 'Our privacy and data protection policy' 
    } 
  },
  { 
    path: 'terms', 
    component: TermsLongComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Terms and Conditions', 
      description: 'Our terms and conditions' 
    } 
  },
  { 
    path: 'terms-short', 
    component: TermsShortComponent, 
    canActivate: [LanguageGuard], 
    data: { 
      title: 'Terms and Conditions', 
      description: 'Our terms and conditions' 
    } 
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
