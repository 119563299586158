import { Injectable } from '@angular/core';
import { getFirestore, collection, addDoc, query, where, getDocs, setDoc, doc, Firestore } from 'firebase/firestore';
import { NewsletterSubscription } from '../../store/models/newsletter-types';
import { serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class NewsletterSubscriptionService {
  private firestore: Firestore = getFirestore();


  async subscribeToNewsletter(subscriptionData: { email: string; newsletterTypes: { userLead: boolean } }): Promise<void> {
    const subscriptionRef = collection(this.firestore, 'Newsletter');
    const q = query(subscriptionRef, where('email', '==', subscriptionData.email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      await setDoc(doc(this.firestore, 'Newsletter', docSnapshot.id), {
        newsletterTypes: subscriptionData.newsletterTypes,
        updatedAt: serverTimestamp()
      }, { merge: true });
    } else {
      const subscription: NewsletterSubscription = {
        email: subscriptionData.email,
        newsletterTypes: subscriptionData.newsletterTypes,
        verified: false,
        verificationToken: this.generateVerificationToken(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        unsubscribed: false,
      };

      const isBlocked = await this.checkIfEmailOrDomainBlocked(subscription.email);
      if (isBlocked) {
        throw new Error('Email Blocked');
      }

      try {
        await addDoc(collection(this.firestore, 'Newsletter'), subscription);
      } catch (error) {
        console.error('Error adding subscription:', error);
        throw error;
      }
    }
  }

  async checkIfEmailOrDomainBlocked(email: string): Promise<boolean> {
    const domain = email.split('@')[1];
    const blacklistRef = collection(this.firestore, 'Blacklist');
    const emailDoc = await getDocs(query(blacklistRef, where('__name__', '==', email)));
    if (!emailDoc.empty) {
      return true;
    }
    const domainDoc = await getDocs(query(blacklistRef, where('__name__', '==', domain)));
    if (!domainDoc.empty) {
      return true;
    }
    return false;
  }

  async getSubscriptionByToken(verificationToken: string): Promise<{ id: string, data: NewsletterSubscription } | null> {
    const q = query(collection(this.firestore, 'Newsletter'), where('verificationToken', '==', verificationToken));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      return { id: docSnapshot.id, data: docSnapshot.data() as NewsletterSubscription };
    } else {
      return null;
    }
  }

  async updateEmailVerification(subscriptionId: string): Promise<void> {
    await setDoc(doc(this.firestore, 'Newsletter', subscriptionId), { 
      verified: true,
      verifiedAt: serverTimestamp()
    }, { merge: true });
  }

  public generateVerificationToken(): string {
    return uuidv4();
  }

  async markAsUnsubscribed(email: string): Promise<void> {
    const q = query(collection(this.firestore, 'Newsletter'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      await setDoc(doc(this.firestore, 'Newsletter', docSnapshot.id), {
        unsubscribed: true,
        unsubscribedAt: serverTimestamp()
      }, { merge: true });
    }
  }
}
