import { Product } from '../../../store/models/product.model';

const BABY_LUNA_UNICORN_CAP: Product = {
  productDetails: {
    productUid: 'VjKpZ3qLbR7mTcE8wXyA',
    productNumber: 'C002',
    productName: 'Baby Luna Unicorn Cap',
    productDescription: 'Exclusive cap featuring Baby Luna, the enchanting Moonborn Unicorn, perfect for fans of magic and adventure.',
    productFeatures: 'Adjustable strap, Breathable fabric, Embroidered Baby Luna design, One size fits all, Comfortable and durable fit',
    productInformation: `
      Elevate your style with the "Baby Luna Unicorn" Cap, an exclusive piece for those who cherish the magic of the cosmos. This cap features an adjustable strap for a perfect fit and is crafted from breathable fabric to ensure comfort throughout the day. The meticulously embroidered Baby Luna design captures the essence of the Moonborn Unicorn's first adventures, making this cap not only a stylish accessory but also a symbol of hope and wonder. Whether you're out exploring or simply want to add a touch of magic to your outfit, this cap is the ideal choice.
    `,
    productStart: new Date(),
    productPublished: false,
  },
  productSpecifics: {
    productType: 'Merchandise',
    fileName: 'baby-luna-unicorn-cap',
    affiliateUrl: '',
    productCollection: 'Luna Baby',
  },
  cost: {
    priceAmountSingleItem: 25,
    priceCurrency: 'EUR',
    tax: 'incl',
    tieredPricing: []
  },
  images: [
    { imageURL: 'baby-luna-unicorn-cap.webp', imageName: 'Baby Luna Unicorn Cap Image' },
  ],
  colors: [
    { name: 'Midnight Black', topColor: '#000000' },
    { name: 'Cosmic Blue', topColor: '#0000FF' }
  ],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered in 5 days' },
    { icon: 'fas fa-truck', info: 'Free shipping from 150 Euros' },
    { icon: 'fas fa-undo-alt', info: '30-day return policy' }
  ]
};

export default BABY_LUNA_UNICORN_CAP;
