import { Component, ViewChild, OnDestroy, OnInit, inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CommonModule, Location, PopStateEvent } from '@angular/common';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterOutlet, RouterModule, ActivatedRoute, Router, NavigationStart, NavigationEnd, Event as NavigationEvent, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/user/user-auth.service';
import { SnackbarService } from './components/snack-bar/snack-bar.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { selectCartItemsCount } from './store/selectors/cart.selectors';
import { AppState } from './store/root';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { FooterComponent } from './pages/other/footer/footer.component';
import { LanguageService } from './services/admin/language.service';
import { LanguagesMapping } from './store/models/language.model';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    LoadingIndicatorComponent,
    CookieConsentComponent,
    FooterComponent,
    TranslateModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private analytics = inject(Analytics);
  @ViewChild('sidenav') sidenav!: MatSidenav;
  title = 'magic-horn';
  isLoggedIn$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isExternalUser$: Observable<boolean>;
  isAccessible$: Observable<boolean>;
  cartItemCount$: Observable<number>;
  languages$: Observable<LanguagesMapping>;
  currentLanguage: string = this.translate.currentLang || 'en';
  isMobileMenuOpen = false;
  isMobile = false;
  isLoading = true;
  isHomeRoute: boolean = false;
  isTapRoute: boolean = false;
  mobileMenuOpen = false;
  logoUrl: string = '/assets/logo/magic-horn-pink-square.png';

  private subscriptions: Subscription = new Subscription();
  private lastPoppedUrl: string | undefined;
  private yScrollStack: number[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbarService: SnackbarService,
    private store: Store<AppState>,
    private translate: TranslateService,
    public languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private location: Location,

  ) {
    this.isLoggedIn$ = this.authService.isAuthenticated();
    this.isAdmin$ = this.authService.isUserInRole('administrator');
    this.isExternalUser$ = this.authService.isUserInRole('externalUser');
    this.isAccessible$ = combineLatest([this.isAdmin$, this.isExternalUser$]).pipe(
      map(([isAdmin, isExternalUser]) => isAdmin || isExternalUser)
    );
    this.cartItemCount$ = this.store.pipe(select(selectCartItemsCount));
    this.languages$ = this.languageService.allLanguages$;
    this.router.events.pipe(
      filter((event: NavigationEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isHomeRoute = event.url === '/' || event.url === '/home';
    });
  }

  ngOnInit() {
    logEvent(this.analytics, 'app_comp_fth_opened', { component: 'AppComponent' });
    this.isMobile = window.innerWidth <= 768;
    this.authService.authState().subscribe(() => {
      this.isLoading = false;
    });
    this.seoRouteDetails();
    this.scrollToTopOnPageload();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  scrollToTopOnPageload() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (ev instanceof NavigationEnd) {
        if (ev.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop() || 0);
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  switchLanguageIcon(language: string) {
    this.languageService.switchLanguage(language).subscribe({
      next: () => {
        this.currentLanguage = language;
      },
      error: error => {
        console.error(`Error switching language to ${language}:`, error);
      }
    });
  }

  private seoRouteDetails() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(event => {
      this.titleService.setTitle(event['title'] || 'Default Title');
      this.metaService.updateTag({ name: 'description', content: event['description'] || 'Default Description' });
    });
  }

  logout(): void {
    this.authService.logoutUser().then(() => {
      console.log('Logout successful');
      this.snackbarService.showCustomSnackbar('Logout successful');
    }).catch(error => {
      console.error('Logout failed:', error);
      this.snackbarService.showCustomSnackbar('Logout failed');
    });
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  closeMobileMenu() {
    this.isMobileMenuOpen = false;
  }

  goToUserDashboard(): void {
    window.location.href = 'https://magic-horn.io/';
  }

  goToUserLogin(): void {
    window.location.href = 'https://magic-horn.io/login';
  }

  goToShop(): void {
    window.location.href = '/shop';
  }
}
