import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressBarModule,
      ],
})
export class SnackBarComponent implements OnInit, OnDestroy {
    progressValue = 0;
    message: string;
    type: string;
    private interval: any;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private snackBarRef: MatSnackBarRef<SnackBarComponent>
    ) {
        this.message = data.message;
        this.type = data.type || 'info';
    }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.progressValue += 2;
            if (this.progressValue >= 100) {
                clearInterval(this.interval);
            }
        }, 100);
    }

    ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    dismiss(): void {
        this.snackBarRef.dismiss();
    }

    
}
