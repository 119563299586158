// store/meta-reducers/local-storage-sync.reducer.ts
import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

function isLocalStorageAvailable(): boolean {
  try {
    const testKey = '__local_storage_test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return (state, action) => {
    const nextState = reducer(state, action);

    if (isLocalStorageAvailable()) {
      if (action.type === INIT || action.type === UPDATE) {
        const savedState = localStorage.getItem('appState');
        if (savedState) {
          return JSON.parse(savedState);
        }
      }

      localStorage.setItem('appState', JSON.stringify(nextState));
    }

    return nextState;
  };
}
