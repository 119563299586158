import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartState } from '../reducers/cart.reducer';

export const selectCartState = createFeatureSelector<CartState>('cart');

export const selectCartItems = createSelector(
  selectCartState,
  (state: CartState) => (state ? state.items : [])
);

export const selectCartLoading = createSelector(
  selectCartState,
  (state: CartState) => (state ? state.loading : false)
);

export const selectCartError = createSelector(
  selectCartState,
  (state: CartState) => (state ? state.error : null)
);

export const selectCartItemsCount = createSelector(
  selectCartItems,
  items => items.reduce((count, item) => count + item.quantity, 0)
);

export const selectSinglePurchaseRestriction = (productId: string) => createSelector(
  selectCartState,
  (state: CartState) => state.singlePurchaseRestrictions?.includes(productId) || false
);

export const selectCartTotalPrice = createSelector(selectCartItems, items =>
  items.reduce((total, item) => {
    const productCost = item.product.cost;
    let effectivePrice = 0;

    if (productCost.freeOfCharge) {
      effectivePrice = 0;
    } else if (productCost.priceAmountSingleItem) {
      effectivePrice = productCost.priceAmountSingleItem;
    }

    return total + effectivePrice * item.quantity;
  }, 0)
);

export const selectCartTotalVAT = createSelector(selectCartTotalPrice, totalPrice => totalPrice * 0.19);
export const selectProductQuantity = (productId: string) => createSelector(
  selectCartItems,
  items => items.find(item => item.product.productDetails.productUid === productId)?.quantity || 1
);
export const selectSelectedColor = (productId: string) => createSelector(
  selectCartItems,
  items => items.find(item => item.product.productDetails.productUid === productId)?.selectedColor || null
);