<div class="legal-wrapper">

    <div class="legal-page">

<h1>Immediate Bank Transfer (Sofortüberweisung)</h1>

<p>Our website allows payment via "Immediate Bank Transfer" (Sofortüberweisung). The provider of this payment service is Sofort GmbH, Theresienhöhe 12, 80339 Munich.</p>

<p>Using the "Immediate Bank Transfer" method, we receive a real-time payment confirmation from Sofort GmbH, allowing us to immediately fulfill our obligations.</p>

<p>When paying via "Immediate Bank Transfer," your PIN and TAN are transmitted to Sofort GmbH. The payment provider logs into your online banking account, automatically checks your account balance, and initiates the transfer. This is followed by an immediate transaction confirmation. After logging in, your transactions, the credit limit of your overdraft facility, the existence of other accounts, and their balances are also automatically checked.</p>

<p>In addition to your PIN and TAN, the transmission to Sofort GmbH also includes payment data and personal data. Personal data includes your first and last name, address, telephone number(s), email address, IP address, and, if necessary, other data required for payment processing. This data transmission is necessary to clearly establish your identity and prevent fraud attempts.</p>

<p>The transmission of your data to Sofort GmbH is based on Art. 6 para. 1 lit. a GDPR (consent) and Art. 6 para. 1 lit. b GDPR (processing for the performance of a contract). You can withdraw your consent at any time. Data processing operations that occurred in the past remain effective even after withdrawal.</p>

<p>Details on payment via "Immediate Bank Transfer" can be found at: <a href="https://www.sofort.de/datenschutz.html" target="_blank">https://www.sofort.de/datenschutz.html</a> and <a href="https://www.klarna.com/sofort/" target="_blank">https://www.klarna.com/sofort/</a>.</p>

<h1>Stripe</h1>

<p>For payment methods such as credit card, SEPA direct debit, Immediate Bank Transfer, and GiroPay, payment processing is carried out through the payment service provider Stripe Payments Europe, Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Ireland.</p>

<p>During the payment process, this provider receives data related to the order, including name, address, account number, bank code (or IBAN), credit card number if applicable, invoice amount, currency, and transaction number. Data is shared solely for the purpose of payment processing and only to the extent necessary for this purpose.</p>

<p>The transmission of your data to Stripe Payments Europe, Ltd is based on Art. 6 para. 1 lit. a GDPR (consent) and Art. 6 para. 1 lit. b GDPR (processing for the performance of a contract). You can withdraw your consent at any time. Data processing operations that occurred in the past remain effective even after withdrawal.</p>

<p>Details about Stripe can be found at: <a href="https://stripe.com/de/about" target="_blank">https://stripe.com/de/about</a>. Further information on Stripe's privacy can be found at <a href="https://stripe.com/de/privacy#translation" target="_blank">https://stripe.com/de/privacy#translation</a>.</p>
</div>
</div>