import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { environment } from '../../../../../environments/environment';
import { AlchemyService } from '../../../../services/blockchain/alchemy.service';
import { Product } from '../../../../store/models/product.model';
import { NftSupplyService } from '../../../../services/blockchain/nft-supply.service';
import { SnackbarService } from '../../../../components/snack-bar/snack-bar.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-web3-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinner
  ],
  templateUrl: './web3-modal.component.html',
  styleUrls: ['./web3-modal.component.scss']
})
export class Web3ModalComponent implements OnInit {
  connected: boolean = false;
  userAddress: string | null = null;
  isMinting: boolean = false;
  overlayText: string = 'initial';
  private modal: any;
  @Input() selectedProduct!: Product;
  @Input() productPrice!: string;

  constructor(
    private alchemyService: AlchemyService,
    private nftSupplyService: NftSupplyService,
    private snackbarService: SnackbarService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.initWeb3Modal();
    this.subscribeToProviderChanges();
  }

  private async initWeb3Modal(): Promise<void> {
    const projectId = environment.walletConnectProjectId;

    const mainnet = {
      chainId: environment.chainId,
      name: 'Ethereum',
      currency: 'ETH',
      explorerUrl: environment.explorerUrl,
      rpcUrl: environment.rpcUrl,
    };

    const metadata = {
      name: 'Magic Horn',
      description: 'Where blockchain magic, fortune, and endless possibilities await.',
      url: 'https://magic-horn.io',
      icons: ['assets/icons/noun-unicorn-2874994.png']
    };

    const ethersConfig = defaultConfig({
      metadata,
      enableEIP6963: true,
      enableInjected: true,
      enableCoinbase: true,
      rpcUrl: mainnet.rpcUrl,
      defaultChainId: mainnet.chainId,
    });

    this.modal = createWeb3Modal({
      ethersConfig,
      chains: [mainnet],
      projectId,
      enableAnalytics: true,
    });

    console.log('Web3Modal initialized:', this.modal);
  }

  private subscribeToProviderChanges(): void {
    this.modal.subscribeProvider(({ isConnected, address }: { isConnected: boolean, address: string | null }) => {
      if (!isConnected) {
        this.handleDisconnect();
      } else {
        this.userAddress = address;
        this.connected = true;
      }
    });
  }

  updateOverlayText(newText: string) {
    this.overlayText = newText;
  }

  private handleDisconnect(): void {
    console.log('Wallet disconnected');
    this.userAddress = null;
    this.connected = false;
  }

  async openWeb3Modal(): Promise<void> {
    try {
      await this.modal.open();
      const address = await this.modal.getAddress();
      this.userAddress = address;
      this.connected = true;
    } catch (error) {
      console.error('Error during wallet connection:', error);
      this.snackbarService.showCustomSnackbar('Error connecting wallet');
    }
  }

  async mintNow(): Promise<void> {
    if (!this.selectedProduct || !this.productPrice) {
      console.error('No product or price selected. Cannot mint.');
      this.snackbarService.showCustomSnackbar('No product or price selected. Cannot mint.');
      return;
    }

    if (!this.connected || !this.userAddress) {
      console.error('Wallet not connected. Cannot mint.');
      this.snackbarService.showCustomSnackbar('Wallet not connected. Cannot mint.');
      return;
    }

    this.isMinting = true; // <-- Set loading state
    try {
      const nft = await this.nftSupplyService.getNftSupplyData(
        this.selectedProduct.productSpecifics.firestoreCollection!,
        this.selectedProduct.productSpecifics.cidAssingment!,
        this.selectedProduct.productSpecifics.cidGroup,
        this.selectedProduct.productSpecifics.cidNumber
      );

      if (!nft) {
        console.error('No CID available for minting.');
        this.snackbarService.showCustomSnackbar('No CID available for minting.');
        this.isMinting = false; // <-- Reset loading state
        return;
      }

      console.log('Minting initiated...');
      await this.alchemyService.mintNft(
        this.selectedProduct,
        nft.tokenURIJson,
        nft.id!,
        this.userAddress,
        this.selectedProduct.productSpecifics.firestoreCollection!,
        this.updateOverlayText.bind(this) // Pass the method as a callback
      );    } catch (error) {
      console.error('Minting failed:', error);
      this.snackbarService.showCustomSnackbar('Minting failed');
    } finally {
      this.isMinting = false; // <-- Reset loading state
    }
  }
}