<div class="web3-wrapper" [class.loading]="isMinting">
  <div class="web3-button">
    <ng-container *ngIf="connected; else connectWallet">
      <button class="button-blue" (click)="mintNow()">Mint Now</button>
      <button class="button-text" (click)="openWeb3Modal()">Set Wallet</button>
    </ng-container>
    <ng-template #connectWallet>
      <button class="button-blue" (click)="openWeb3Modal()">Connect Wallet</button>
    </ng-template>
  </div>

  <div *ngIf="isMinting" class="loading-overlay">
    <mat-spinner></mat-spinner> 
    <p *ngIf="!overlayText || overlayText === 'initial'">
      Minting in progress, check your wallet for confirmation if needed
    </p>
    <p *ngIf="overlayText === 'in-progress'">
      Minting the NFT, please wait...
    </p>
  </div>

  <!-- Debugging Output -->
  <div class="connection-info">
    <div class="connection-box">
      Connected to wallet:
      <i *ngIf="connected" class="fas fa-check" style="color: green;"></i>
      <i *ngIf="!connected" class="fas fa-times" style="color: red;"></i>
    </div>
    <p *ngIf="connected">Wallet Address: {{ userAddress }}</p>
  </div>
</div>
