import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-ad-redirect',
  templateUrl: './ad-redirect.component.html',
  styleUrls: ['./ad-redirect.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class AdRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const adId = this.route.snapshot.queryParamMap.get('adid');
    if (adId) {
      this.redirectToLandingPage(adId);
    } else {
      this.router.navigate(['home']);
    }
  }

  redirectToLandingPage(adId: string): void {
    let targetUrl = '';

    switch (adId) {
      case 'AD1234':
        targetUrl = 'lp/sign-me-up-scotty';
        break;
      case 'QRCOASTERINFO235':
        targetUrl = 'lp/you-found-us';
        break;
      case 'QRCOASTERSAMPLE235':
        targetUrl = 'tap/the-cozy-corner-pub/hamburg-city-center/main-menu';
        break;
      default:
        targetUrl = 'home';
        break;
    }
    this.router.navigate([targetUrl], { queryParams: this.route.snapshot.queryParams });
  }
}

// sample: https://mikes.menu/lp/?adid=QRCOASTERINFO235

// AdWords sample: https://mikes.menu/lp/?adid=AD1234&campaign=spring_sale&source=google