import { Network } from 'alchemy-sdk';

export const environment = {
    production: false,
    appVersion: 'DEVELOPMENT',
    firebaseConfig: {
        apiKey: "AIzaSyDMwF6zZ71lTwms3_WdIee-oxuwhDWKvas",
        authDomain: "magic-horn.firebaseapp.com",
        projectId: "magic-horn",
        storageBucket: "magic-horn.appspot.com",
        messagingSenderId: "996496608884",
        appId: "1:996496608884:web:d964a5801809026c110b21",
        measurementId: "G-8T9FECTW7D"
    },
    
    firebaseACKeyH: '6Lf0aCcqAAAAAMGmwSAXdJ0Urnr75K9rAcIJ7Zzl',
    paypalTestClientId: 'Aa3f-YMswEh1GbngPjp9Iyfd3jlHuuunN5bXag4UtDNpvkcTwieUDKjDZHbw4qxSZkAc1Wq236gjFd7W',
    stripeLivePublishableKey: 'pk_live_51PRYKIG77Zg4px6YRCQ5jeXthhTzPJP6G50W1uBJZGmX0cpXkfwxVz6PZoYem5VMN1Cg5b52zDnpwe9qf4N6uPbT00voxxB0nk',
    stripeTestPublishableKey: 'pk_test_51PdE2RRpDpfGCywOAE6n1uezgSEJYr8zns4ENXgvntsGffIFiXgGR5XscBq8A80v470Yi9Yaj3qVLcdPrxsBBV3100BMN7FeSw',
    functionsUrl: 'https://europe-west3-magic-horn.cloudfunctions.net',
    createStripeShopPayment: '/createStripeShopPayment',
    walletConnectProjectId: '7c4944fb50da453b3b278ee12ae4d4ae',
    nftSignerSecretKey: "5b7430831eb30ef49e2b77e268905f51142c660b75baa88df97389a046d49ad4",
    alchemyApiKey: 'wsShmBOU-I3BhZwdlU--MrVLPPb7fNPK',
    alchemyNetwork: Network.ETH_SEPOLIA,
    chainId: 11155111,
    explorerUrl: 'https://sepolia.etherscan.io',
    rpcUrl: 'https://eth-sepolia.g.alchemy.com/v2/wsShmBOU-I3BhZwdlU--MrVLPPb7fNPK',
};