import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Product } from '../../store/models/product.model';
import BABY_LUNA_UNICORN_TSHIRT from '../../pages/shop/products/baby-luna-tshirt.data';
import BABY_LUNA_UNICORN_CAP from '../../pages/shop/products/baby-luna-cap.data';
import BABY_LUNA_PLUSH_TOY from '../../pages/shop/products/baby-luna-plush-toy.data';

// Import the NFT Baby Luna products
import NFT_BABY_LUNA_COMMON from '../../pages/shop/products/nft-baby-luna-common.data';
import NFT_BABY_LUNA_UNCOMMON from '../../pages/shop/products/nft-baby-luna-uncommon.data';
import NFT_BABY_LUNA_RARE from '../../pages/shop/products/nft-baby-luna-rare.data';
import NFT_BABY_LUNA_EPIC from '../../pages/shop/products/nft-baby-luna-epic.data';
import NFT_BABY_LUNA_LEGENDARY from '../../pages/shop/products/nft-baby-luna-legendary.data';

import NFT_RAINBOW_PORTAL from '../../pages/shop/products/nft-rainbow-portal.data';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private products: Product[] = [
    BABY_LUNA_UNICORN_TSHIRT,
    BABY_LUNA_UNICORN_CAP,
    BABY_LUNA_PLUSH_TOY,
    NFT_BABY_LUNA_COMMON,
    NFT_BABY_LUNA_UNCOMMON,
    NFT_BABY_LUNA_RARE,
    NFT_BABY_LUNA_EPIC,
    NFT_BABY_LUNA_LEGENDARY,
    NFT_RAINBOW_PORTAL
  ];

  constructor() {}

  getProduct(productId: string): Observable<Product | undefined> {
    const product = this.products.find(p => p.productDetails.productUid === productId);
    return of(product);
  }

  getAllProducts(): Observable<Product[]> {
    return of(this.products);
  }
}
