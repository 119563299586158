// src/app/services/other/cookie-consent.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  private consentKey = 'cookie_consent';

  constructor() {}

  hasConsented(): boolean {
    return localStorage.getItem(this.consentKey) === 'true';
  }

  setConsent(consent: boolean): void {
    localStorage.setItem(this.consentKey, consent.toString());
    if (consent) {
      this.configureGoogleAnalytics();
    }
  }

  private configureGoogleAnalytics(): void {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) { window.dataLayer.push(args); }
    window.gtag = gtag;
    gtag('config', 'G-REKP5TTZY6', {
      'anonymize_ip': true
    });
  }
}
