import { Product } from '../../../store/models/product.model';

const BABY_LUNA_PLUSH_TOY: Product = {
  productDetails: {
    productUid: 'oMn7QwY8Dzr6LgHf9CsX',
    productNumber: 'T002',
    productName: 'Plush Unicorn Toy',
    productDescription: 'Adorable and soft unicorn plush toy, perfect for unicorn lovers of all ages.',
    productFeatures: 'Super soft fabric, Embroidered eyes, Rainbow-colored mane and tail, Suitable for all ages, Washable material',
    productInformation: `
      Bring the magic of unicorns to life with our "Plush Unicorn Toy." This cuddly unicorn plush is made from the softest materials, making it perfect for snuggling and playtime. It features a beautifully embroidered face with sparkling eyes, and a rainbow-colored mane and tail that add a touch of whimsy. Whether you're looking for a new friend for your child, a decorative piece for your room, or a special gift for a unicorn lover, this plush toy is sure to be cherished. Its high-quality, washable fabric ensures it will stay soft and clean, even after countless hugs and adventures.
    `,
    productStart: new Date(),
    productPublished: false,
  },
  productSpecifics: {
    productType: 'Merchandise',
    fileName: 'plush-unicorn-toy',
    affiliateUrl: '',
    productCollection: 'Fabric Unicorn',
  },
  cost: {
    priceAmountSingleItem: 45,
    priceCurrency: 'EUR',
    tax: 'incl',
    tieredPricing: []
  },
  images: [
    { imageURL: 'plush-unicorn-toy.webp', imageName: 'Plush Unicorn Toy Image' },
  ],
  colors: [
    { name: 'Snow White', topColor: '#FFFFFF' },
    { name: 'Pastel Pink', topColor: '#FFB6C1' }
  ],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered in 5 days' },
    { icon: 'fas fa-truck', info: 'Free shipping from 100 Euros' },
    { icon: 'fas fa-undo-alt', info: '30-day return policy' }
  ]
};

export default BABY_LUNA_PLUSH_TOY;
