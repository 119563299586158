import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AuthService } from '../../services/user/user-auth.service';
import { Observable} from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { FeedbackCarouselComponent } from '../../components/feedback-carousel/feedback-carousel.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatSlideToggleModule,
    RouterModule,
    FeedbackCarouselComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  isLoggedIn$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.isLoggedIn$ = this.authService.isAuthenticated();
  }

  navigateToProduct() {
    this.router.navigate(['/shop/product-details/o7LnWpXqT8YkVr4Hc5F']);
  }

}
