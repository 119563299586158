import { Component, AfterViewInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconButton } from '@angular/material/button';
import * as QRCode from 'qrcode';
import { Router, RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackCarouselComponent } from '../../../components/feedback-carousel/feedback-carousel.component';
import { NftService } from '../../../services/blockchain/etherjs.service';
import { HttpClient } from '@angular/common/http';
import { AlchemyService } from '../../../services/blockchain/alchemy.service';


@Component({
  selector: 'app-baby-luna',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconButton,

    MatProgressSpinnerModule,
    RouterModule,
    CarouselModule,
    TranslateModule,
    FeedbackCarouselComponent
  ],
  templateUrl: './baby-luna.component.html',
  styleUrls: ['./baby-luna.component.scss']
})
export class NftBabyLunaMarketplaceComponent implements AfterViewInit {
  @ViewChild('reviewStarsContainer') reviewStarsContainer?: ElementRef;
  @ViewChild('qrCanvas', { static: false }) qrCanvas!: ElementRef;
  loading = false;
  totalSupply: number = 0;
  nfts: any[] = [];

  reviews = [
    { name: "coming soon", date: "in the future", text: "For sure, our reviews will be awesome; at least, this is what our independent test users have said so far!" },
    { name: "Alice Johnson", date: "Sep 15, 2024", text: "Seamless check-in and check-out process! I was able to handle everything directly from the app with no hassle. Definitely a five-star experience!" },
    { name: "Michael Chen", date: "Aug 22, 2024", text: "Top-notch security features! I felt really secure with the in-app verification steps and the way they handle personal data. Highly recommend for peace of mind." },
    { name: "Sarah Brown", date: "Oct 02, 2024", text: "The app made managing my booking details so easy! I could update my reservation on-the-go and even extend my stay in just a few taps. A perfect tool for frequent travelers." },
    { name: "Raj Patel", date: "Jul 18, 2024", text: "I’m impressed by the intuitive design of the app which made navigating through options a breeze. Checking room availability, prices, and making payments was straightforward and quick. This app makes hostel stays worry-free." },
    { name: "Emily Turner", date: "Nov 05, 2024", text: "Fantastic features for managing personal information securely. The way the app handles data privacy is top-notch and gives me confidence in using it for all my hostel stays." }
  ];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    dots: false,
    items: 1,
    nav: false
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,

    private http: HttpClient,
    private router: Router,
    private alchemyService: AlchemyService
  ) {
  }

  ngOnInit(): void {
   // this.fetchNftSupply();
    this.loadNftData();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.generateQRCode();
    }
  }

  fetchSentTransactions() {
    const fromAddress = '0xYourEthereumAddressHere'; // Replace with actual address
    this.alchemyService.getSentTransactions(fromAddress);
  }



  loadNftData() {
    this.http.get<any[]>('assets/nfts-test.json').subscribe({
      next: data => {
        this.nfts = data;
      },
      error: error => {
        console.error('Error loading NFT data', error);
      }
    });
  }
  generateQRCode(): void {
    const canvas = this.qrCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Could not get canvas context.');
      return;
    }
  
    const qrData = 'https://coming-soon';
    QRCode.toCanvas(canvas, qrData, {
      errorCorrectionLevel: 'high',
      margin: 2,
      scale: 4,
      width: 150,
      color: {
        dark: '#031D2D',
        light: '#FFFFF5'
      }
    }, (error: any) => {
      if (error) {
        console.error('Error generating QR code:', error);
      }
    });
  }

  openShopWithLunaFilter() {
    this.router.navigate(['/shop'], {
      queryParams: {
        productType: 'NFT',
        productCollection: 'Luna Baby'
      }
    });
  }
}
