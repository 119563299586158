import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../services/user/user-auth.service';
import { UsersRudService } from '../../../services/user/users-rud.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule
  ],
  providers: [AuthService, UsersRudService]
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Output() stepCompleted = new EventEmitter<any>();

  isLoggedIn$ = this.authService.authState().pipe(map(user => !!user));
  detailsFormGroup!: FormGroup;
  enteredEmail: string = '';
  termsAccepted: boolean = false;
  showRegistration = false;
  emailLoginFormGroup!: FormGroup;
  private destroy$ = new Subject<void>();
  private subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router 
  ) {
    this.emailLoginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]]
    }, { validator: emailMatchValidator });

    this.detailsFormGroup = this.formBuilder.group({
      userDetails: this.formBuilder.group({
        userFirstName: [''],
        userLastName: [''],
        userCompany: [''],
        userCountry: ['']
      }),
      userAddress: this.formBuilder.group({
        userStreet: [''],
        userStreet2: [''],
        userCity: [''],
        userPostalCode: [''],

      })
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }

  logout(event: Event): void {
    event.preventDefault();
    this.authService.logoutUser().then(() => {
      this.showRegistration = false;
    });
  }

  onConfirmUserDetails() {
    if (this.detailsFormGroup.valid && this.emailLoginFormGroup.valid && !this.emailLoginFormGroup.hasError('emailMismatch')) {
      const userDetails = {
        ...this.detailsFormGroup.value,
        email: this.emailLoginFormGroup.get('email')?.value
      };
      this.stepCompleted.emit(userDetails);
      this.router.navigate(['/shop/checkout'], { state: { userDetails } });  // Navigate to checkout
    }
  }
}

function emailMatchValidator(control: FormGroup): { [key: string]: boolean } | null {
  const email = control.get('email');
  const confirmEmail = control.get('confirmEmail');
  if (email && confirmEmail && email.value !== confirmEmail.value) {
    return { 'emailMismatch': true };
  }
  return null;
}
