import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NewsletterSubscriptionService } from '../../../services/user/newsletter-subscription.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    NgIf,
    AsyncPipe,
    FormsModule,
    TranslateModule,
  ]
})
export class VerifyEmailComponent implements OnInit {
  verificationState: 'verified' | 'error' | 'pending' | 'already_verified' = 'pending';

  constructor(
    private route: ActivatedRoute,
    private newsletterService: NewsletterSubscriptionService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.verifyEmail(token);
    }
  }

  async verifyEmail(token: string): Promise<void> {
    try {
      const subscription = await this.newsletterService.getSubscriptionByToken(token);
      if (subscription) {
        if (subscription.data.verified) {
          this.verificationState = 'already_verified';
        } else {
          await this.newsletterService.updateEmailVerification(subscription.id);
          this.verificationState = 'verified';
        }
      } else {
        this.verificationState = 'error';
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      this.verificationState = 'error';
    }
  }
}
