<div id="user-details">

  <div class="user-details-wrapper">
    <div class="user-details-container">
      <div class="user-details-header">
        <p>Checkout: Enter your details</p>
      </div>

      <div class="email-form-wrapper">
        <div class="email-inputfields-header">Associated Email Address</div>
        <form [formGroup]="emailLoginFormGroup">
          <div class="email-inputfields">
            <mat-form-field appearance="fill">
              <mat-label>e-mail</mat-label>
              <input matInput type="email" formControlName="email">
              <mat-error *ngIf="emailLoginFormGroup.get('email')?.hasError('email')">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>repeat your e-mail</mat-label>
              <input matInput type="email" formControlName="confirmEmail">
              <mat-error *ngIf="emailLoginFormGroup.hasError('emailMismatch')">
                Email addresses do not match.
              </mat-error>
            </mat-form-field>
          </div>
        </form>
        <div class="email-info">
          <div class="email-info-header">
            I understand that the Email Address is used for my
            registration and
            therefore it is important that it is correct! The Email with the login Information will be sent after
            successful payment!

          </div>
          <mat-checkbox [(ngModel)]="termsAccepted">Please accept, you understand the link to your email!</mat-checkbox>
        </div>
      </div>
      <div class="user-adress-wrapper">
        <div class="user-adress-header">Billing / Shipping Address</div>
        <form [formGroup]="detailsFormGroup" class="user-adress-form">
          <div class="form-columns">
            <div class="form-column" formGroupName="userDetails">
              <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="userFirstName">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="userLastName">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Company</mat-label>
                <input matInput formControlName="userCompany">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Country</mat-label>
                <input matInput formControlName="userCountry">
              </mat-form-field>
            </div>
            <div class="form-column" formGroupName="userAddress">
              <mat-form-field appearance="fill">
                <mat-label>Street</mat-label>
                <input matInput formControlName="userStreet">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Street 2</mat-label>
                <input matInput formControlName="userStreet2">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Postal Code</mat-label>
                <input matInput formControlName="userPostalCode">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>City</mat-label>
                <input matInput formControlName="userCity">
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="button-container">
        <button class="button-blue" (click)="onConfirmUserDetails()"
        [disabled]="emailLoginFormGroup.invalid || emailLoginFormGroup.hasError('emailMismatch') || !termsAccepted">
        <span>Go to Payment</span>
      </button>
      </div>

    </div>
  </div>
</div>