import { Product } from '../../../store/models/product.model';

const NFT_BABY_LUNA_UNCOMMON: Product = {
  productDetails: {
    productUid: 'Mq6NzWcYr9PfXtL8oKaB',
    productNumber: 'NFT-002',
    productName: 'NFT Baby Luna Uncommon',
    productDescription: 'Enhanced Baby Luna NFT with added details, ideal for those seeking something unique.',
    productFeatures: `
    Distinctive digital art that elevates the Baby Luna collection with additional attributes and a more refined design. This NFT is backed by blockchain-verified ownership, providing a secure and indisputable record of authenticity and ownership on the Ethereum network. The uncommon rarity enhances its desirability, making it a sought-after item in secondary markets where collectors value its balance between rarity and accessibility. The Baby Luna Uncommon NFT stands out with its enhanced features, offering collectors an opportunity to acquire a more unique and limited piece of digital art that is both a collectible and an investment.
  `,
      productInformation: `
    The Uncommon Baby Luna NFT stands out with additional attributes and a more intricate design, catering to collectors who seek something beyond the basics. As part of the Baby Luna collection, this NFT offers a balanced mix of rarity and affordability, making it an attractive choice for enthusiasts who wish to expand their collection with a piece that is both distinctive and moderately rare. The Uncommon Baby Luna features enhanced artistic details and limited availability, setting it apart from the common version. This level of rarity makes it a desirable asset for those looking to invest in NFTs with a higher potential for appreciation while still remaining within a reasonable price range.
  `,
      productStart: new Date(),
      productPublished: false,
  },
  productSpecifics: {
    productType: 'nft',
    fileName: 'nft-baby-luna-uncommon',
    productCollection:'Luna Baby',
    firestoreCollection: 'NFT_LunaBaby',
    affiliateUrl: '',
    smartContractDetails: `
  The Uncommon Baby Luna NFT is managed by an advanced ERC-721 smart contract that includes enhanced features to handle NFTs of higher rarity. With a mint fee of 0.1 ETH, the contract ensures secure ownership and verification on the Ethereum blockchain. The Uncommon rarity level has a lower supply than Common, offering additional attributes that make these NFTs more desirable. The contract manages these tokens with added care, ensuring their metadata reflects the Uncommon status, and supports seamless trading across wallets and marketplaces.
`,

  },
  cost: {
    priceAmountSingleItem: 0.1,
    priceCurrency: 'ETH',
    tax: 'none',
    tieredPricing: [],
  },
  images: [
    { imageURL: 'babyunicorn.webp', imageName: 'Uncommon Baby Luna NFT Image' },
  ],
  colors: [],
  shopDetails: [
    { icon: 'fas fa-shipping-fast', info: 'Delivered instantly on purchase' },
    { icon: 'fas fa-truck', info: 'Blockchain verified' },
    { icon: 'fas fa-undo-alt', info: 'No refunds' },
  ],
};

export default NFT_BABY_LUNA_UNCOMMON;
