<div class="legal-wrapper">

    <div class="legal-page">

        <h1>Detailed Information for the Cautious and the Contentious, Know-it-all Educators</h1>

        <p><strong>Responsible for the content according to § 5 TMG:</strong></p>

        <address>
            Sine Charta Solutions<br>

        </address>


        <p><strong>Represented by the Managing Director:</strong></p>
        <p>Michael Friedrich<br>
            Email: <a href="mailto:we@hos.tel">we [ät] hos.tel</a></p>

        <p>the just tell me is the sole licensee and responsible party for the content on the hos.tel website and its
            subdomains.</p>
        <p>the just tell me is a registered trademark of YingYang.</p>
        <p>Website design and programming by YingYang., carried out by YingYang.</p>

        <p><strong>Responsible for content according to § 55 paragraph 2 RStV:</strong></p>
        <p>The respective author is responsible for the content of the texts.<br>
            You can find members of the editorial staff in the respective blog articles at the bottom.</p>

        <p>Online dispute resolution according to Art. 14 paragraph 1 ODR-VO: The European Commission provides a
            platform for online dispute resolution (OS).<br>
            You can find it at <a href="http://ec.europa.eu/consumers/odr/"
                target="_blank">http://ec.europa.eu/consumers/odr/</a></p>

        <h2>Disclaimer</h2>
        <h1>Notice</h1>
        <p><strong>Note:</strong></p>
        <p>All rights, including those of photomechanical reproduction, reproduction, and distribution by special
            methods (such as data processing, data carriers, data networks), even in part, are reserved by Trinity.Town
            GmbH. Trinity.Town GmbH assumes no liability for any typographical errors or the accuracy of entries.</p>

        <p><strong>Your Data Protection</strong></p>
        <p>We appreciate your interest in our company and our products or services and want you to feel secure when
            visiting our websites regarding the protection of your personal data. Because we take the protection of your
            personal data very seriously. Compliance with the provisions of the Federal Data Protection Act is a matter
            of course for us. We want you to know when we collect what data and how we use it. We have taken technical
            and organizational measures to ensure that the data protection regulations are observed by us as well as by
            external service providers commissioned by us.</p>

        <p><strong>Usage Rights</strong></p>
        <p>All texts, all photos, and all design elements of this website are protected by copyright for the publisher
            unless otherwise indicated. Any use beyond the narrow limits of copyright law is not permitted without the
            written consent of the publisher. All rights reserved. In addition to proprietary images, this page contains
            images from www.shutterstock.com and https://pixabay.com/de/.</p>

        <p><strong>Internet Conditions</strong></p>
        <p>The Internet operates as a decentralized network with the caching and transmission of temporary data.
            Therefore, there can be no guarantee of excluding manipulation, accidental distortion, and ensuring the use
            of only current data (e.g., old versions still in the cache) on the connection path from provider to user.
            We therefore point out the following due to this system-related limitation: You receive this data under the
            conditions of the Internet. We cannot guarantee the accuracy of the information or its correspondence with
            the original data.</p>

        <p><strong>Note on Affiliate Programs</strong></p>
        <p>We are participants in the affiliate program of Amazon Europe S.à r.l., designed for websites to earn
            advertising fees by placing advertisements and links to amazon.de.</p>
        <p>There is no change in the price for you. However, in the event of a purchase, we receive a small commission.
        </p>
        <p>If you use such a link and decide to make a purchase, you will need an Amazon customer account. The terms of
            Amazon apply to this. The purchase contract is concluded between you and the respective seller. Therefore,
            we also do not guarantee the products you purchase.</p>
        <p>We also do not guarantee the accuracy, completeness, and timeliness of all published information about these
            products or their availability. We are not liable for errors or omissions on our websites.</p>

        <p><strong>Liability for Content</strong></p>
        <p>As a service provider, we are responsible for our own content on these pages in accordance with general laws.
            However, according to §§ 8 to 10 TMG, we are not obliged to monitor transmitted or stored third-party
            information or to investigate circumstances that indicate illegal activity. Obligations to remove or block
            the use of information under general law remain unaffected. However, liability in this regard is only
            possible from the time of knowledge of a specific legal violation. If we become aware of such legal
            violations, we will remove this content immediately.</p>

        <p><strong>Liability for Links</strong></p>
        <p>Our offer contains links to external websites of third parties, on whose contents we have no influence.
            Therefore, we cannot assume any liability for these external contents. The respective provider or operator
            of the pages is always responsible for the contents of the linked pages. The linked pages were checked for
            possible legal violations at the time of linking. Illegal content was not recognizable at the time of
            linking. However, a permanent control of the content of the linked pages is not reasonable without concrete
            evidence of a violation of the law. If we become aware of legal violations, we will remove such links
            immediately.</p>

        <p><strong>Copyright</strong></p>
        <p>The content and works created by the site operators on these pages are subject to German copyright law. The
            reproduction, editing, distribution, and any kind of use outside the limits of copyright law require the
            written consent of the respective author or creator. Downloads and copies of this page are only permitted
            for private, non-commercial use. Insofar as the content on this page was not created by the operator, the
            copyrights of third parties are respected. In particular, third-party content is identified as such. Should
            you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware
            of any infringements, we will remove such content immediately.</p>

        <p><strong>Trademark Rights</strong></p>
        <p>We expressly point out that the designations, trademarks, logos, and brand names mentioned and displayed on
            this website are the property of the respective companies and/or individuals and are subject to copyright.
        </p>

        <p><strong>Note on Blog Recommendations</strong></p>
        <p>Our recommendations regarding health, especially in the areas of nutrition, fitness, self-defense, martial
            arts, and combat sports, are based on our own experience and personal conviction. They are intended for
            adults and physically healthy individuals. They do not in any way substitute for medical advice. In
            particular, we recommend that everyone first have their fitness for sports confirmed by a doctor.</p>
        <p>Our product reviews and product recommendations are based on personal experiences, our own tests, but also
            extensive internet research. They are intended as a guide for making a purchase decision. Claims for damages
            cannot be made from this.</p>
        <blockquote>
            <p>And where a wise man disputes and a fool, the former gently also puts a bolt before it. But where two
                fools quarrel with each other, the battle rages, and no one sets him boundaries.<br>
            <p>Und wo ein Weiser streitet und ein Tor, schiebt jener leis auch einen Riegel vor. Doch wo zwei Toren
                miteinander zanken, / da tobt der Kampf, und niemand setzt ihm Schranken.<br>
                Friedrich Rückert (1788 – 1866)</p>
        </blockquote>

    </div>
</div>