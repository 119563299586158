import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-short',
  templateUrl: './terms-short.component.html',
  styleUrls: ['./terms-short.component.scss']
})
export class TermsShortComponent {

}
