import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { LanguagesMapping } from '../../store/models/language.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/root';
import * as LanguageActions from '../../store/actions/language.actions';
import { BlogPostCreateService } from '../other/blog-post-create.service';
import { SnackbarService } from '../../components/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  private allLanguagesSource = new BehaviorSubject<LanguagesMapping>({});
  public allLanguages$: Observable<LanguagesMapping> = this.allLanguagesSource.asObservable();
  public initPageLoaded = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private store: Store<AppState>,
    public blogService: BlogPostCreateService,
    private snackbarService: SnackbarService // Inject the SnackbarService
  ) {
    this.subscriptions.add(
      this.store.select('language').subscribe(lang => {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
      })
    );

    this.subscriptions.add(
      this.router.events.pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        const page = this.getPageFromUrl(event.urlAfterRedirects);
        this.loadTranslationsForPage(page);
        this.loadTranslationsForAppComponent();
      })
    );

    this.fetchLanguages();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public initializeLanguage(): void {
    const initialLang = localStorage.getItem('appLang') || this.translate.getBrowserLang() || 'de';
    this.translate.setDefaultLang(initialLang);
    this.translate.use(initialLang).subscribe({
      next: () => {
        const page = this.getPageFromUrl(this.router.url);
        this.loadTranslationsForPage(page);
        this.loadTranslationsForAppComponent();
      },
      error: err => {
        console.error(`Error initializing language: ${err}`);
      }
    });
  }
  

  fetchLanguages(): void {
    this.http.get<LanguagesMapping>('/assets/i18n/languages.json').pipe(
      catchError(error => {
        console.error('[LANGUAGESERVICE1] Failed to load language data:', error);
        return of({});
      }),
      tap(languages => {
        if (Object.keys(languages).length === 0) {
          console.error('[LANGUAGESERVICE2] No languages found or loaded.');
        }
      })
    ).subscribe(languages => {
      this.allLanguagesSource.next(languages);
    });
  }

  loadTranslations(forceReload: boolean = false): Observable<void> {
    if (forceReload || !this.initPageLoaded.getValue()) {
      const page = this.getPageFromUrl(this.router.url);
      return this.store.select(state => state.language).pipe(
        switchMap(lang => {
          return this.loadPageTranslations(lang, page);
        }),
        catchError(error => {
          console.error(`[LANGUAGESERVICE4] Error during loading translations:`, error);
          return of(undefined as void);
        })
      );
    } else {
      console.log(`Translations are already loaded or no force reload required.`);
      return of(undefined as void);
    }
  }
  

  switchLanguage(language: string): Observable<void> {
    this.store.dispatch(LanguageActions.setLanguage({ language }));
    return this.loadTranslations(true);
  }

  getCurrentLanguage(): Observable<string> {
    return this.store.select('language');
  }

  private loadTranslationsForPage(page: string): void {
    this.getCurrentLanguage().pipe(
      switchMap(lang => this.loadPageTranslations(lang, page)),
      catchError(error => {
        console.error(`[LANGUAGESERVICE4] Error during loading translations for page ${page}:`, error);
        return of(undefined as void);
      })
    ).subscribe();
  }

  private loadPageTranslations(lang: string, page: string): Observable<void> {
    const path = `/assets/i18n/pages/${page}/${lang}.json`;
    return this.http.get<any>(path).pipe(
      tap(data => {
        this.translate.setTranslation(lang, data, true);
        this.translate.use(lang);
        this.initPageLoaded.next(true);
        this.blogService.updateLanguage(lang);
        this.store.dispatch(LanguageActions.setLanguage({ language: lang }));
      }),
      catchError(error => {
        console.error(`[LANGUAGESERVICE3] Failed to load translations for ${lang} from path ${path}:`, error);
        this.initPageLoaded.next(false);
        return this.loadFallbackTranslations(page);
      })
    );
  }

  private loadFallbackTranslations(page: string): Observable<void> {
    const fallbackLang = 'de';
    const fallbackPath = `/assets/i18n/pages/${page}/${fallbackLang}.json`;

    return this.http.get<any>(fallbackPath).pipe(
      tap(data => {
        this.translate.setTranslation(fallbackLang, data, true);
        this.translate.use(fallbackLang);
        this.initPageLoaded.next(true);
        this.blogService.updateLanguage(fallbackLang);
        this.store.dispatch(LanguageActions.setLanguage({ language: fallbackLang }));
      }),
      catchError(error => {
        console.error(`[LANGUAGESERVICE5] Failed to load fallback translations from path ${fallbackPath}:`, error);
        return throwError(() => new Error(`Failed to load fallback translations: ${error}`));
      })
    );
  }

  private loadTranslationsForAppComponent(): void {
    this.getCurrentLanguage().pipe(
      switchMap(lang => this.loadAppComponentTranslations(lang)),
      catchError(error => {
        console.error(`[LANGUAGESERVICE6] Error during loading translations for app component:`, error);
        return of(undefined as void);
      })
    ).subscribe();
  }

  private loadAppComponentTranslations(lang: string): Observable<void> {
    const path = `/assets/i18n/pages/app/${lang}.json`;
    return this.http.get<any>(path).pipe(
      tap(data => {
        this.translate.setTranslation(lang, data, true);
        this.translate.use(lang);
      }),
      catchError(error => {
        console.error(`[LANGUAGESERVICE7] Failed to load translations for ${lang} from path ${path}:`, error);
        return this.loadFallbackAppComponentTranslations();
      })
    );
  }

  private loadFallbackAppComponentTranslations(): Observable<void> {
    const fallbackLang = 'de';
    const fallbackPath = `/assets/i18n/pages/app/${fallbackLang}.json`;

    return this.http.get<any>(fallbackPath).pipe(
      tap(data => {
        this.translate.setTranslation(fallbackLang, data, true);
        this.translate.use(fallbackLang);
      }),
      catchError(error => {
        console.error(`[LANGUAGESERVICE8] Failed to load fallback translations from path ${fallbackPath}:`, error);
        return throwError(() => new Error(`Failed to load fallback translations: ${error}`));
      })
    );
  }

  private getPageFromUrl(url: string): string {
    const path = url.split('?')[0];
    const segments = path.split('/').filter(segment => segment);
    if (segments.length === 0) {
      return 'app';
    } else {
      return segments[0];
    }
  }
}
